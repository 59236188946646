import { useEffect, useState, Ref, forwardRef, useImperativeHandle } from "react";
import { useFormik } from "formik";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { useLoader } from "../../../shared/contexts/LoadingContext";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { useDropdown } from "../../../shared/contexts/domains/DropdownContext";
import Title from "../../../components/Title";
import { Module } from "../../../shared/models/course/Module";
import InputSelect from "../../../components/InputSelect";
import SpinnerScreen from "../../../components/SpinnerScreen";
import Input from "../../../components/Input";
import InputMasked from "../../../components/InputMasked";
import AddCourseModule from "./AddCourseModule";
import EditCourseModule from "./EditCourseModule";
import { CourseModuleType } from "../../../shared/models/domains/Domains";
import { IStepFour } from "../../../shared/models/course/Course";
import schema from "./schema";
import { Buttons, Container, ModuleChoice, Text } from "./styles";

interface RefObject {
    switchOrSubmitStep4: (newTargetStep: number|undefined) => void;
}
interface IStep4 {
    setStep: (step: number) => void;
    className?: string;
    type: 'create' | 'edit' | 'read';
    courseCode: number;
}

const StepFour = forwardRef((
    {
        setStep,
        className,
        type,
        courseCode = 0
    }: IStep4,
    ref: Ref<RefObject>
) => {
    const [targetStep, setTargetStep] = useState<number|undefined>(undefined);
    const { courseModuleTypes } = useDropdown();
    const { loading } = useLoader();
    const {
        loadingModulesList,
        dataStepFour,
        fetchStepFour,
        editStepFour,
        removeModules,
    } = useCourse();
    const [canEditModule, setCanEditModule] = useState<boolean>(false);
    const [isEditingModule, setIsEditingModule] = useState<boolean>(false);
    const [isAddingModule, setIsAddingModule] = useState<boolean>(false);
    const [stepValues, setStepValues] = useState<IStepFour>({
        idTipoDeModuloSelecionado: 2,
        tipoDeModuloOutro: '',
        modulos: []
    });

    // const handleModuleOrderChange = (e: any) => {
    //     console.log(e);
    //     formik?.setFieldValue
    // };

    const refreshModulesList = () => {
        fetchStepFour(courseCode);
    }

    const handleAddCourseModuleClick = () => {
        console.log("Criando um módulo...");
        setIsAddingModule(true);
    }

    const handleEditCourseModulesClick = () => {
        console.log("Editando um módulo...");
        setIsEditingModule(true);
    }    

    const handleSortCourseModuleClick = () => {
        const newOrder: Module[] = formik.values.modulos.sort( // dataStepFour.modulos.sort
            (a,b) => a.ordem<b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0
        );
        setStepValues({
            ...stepValues,
            modulos: newOrder
        });
        // console.log("Ordenou os módulos: ", newOrder);
    }

    const handleRemoveCourseModulesClick = () => {
        const selectedModules = formik?.values?.modulos?.filter(x => x.selecionada === true);
        const selectedModuleIds = selectedModules.map(x => x.id);
        console.log("Ids dos módulos a remover: ", selectedModuleIds);
        removeModules(courseCode, selectedModuleIds, refreshModulesList);
    }

    const handleBackClick = () => {
        setStep(3);
    };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleCallbackSubmit = () => {
        if(targetStep) {
            setStep(targetStep);
            // setTargetStep(undefined);
        }
        else {
            setStep(5)
        }
    }

    const handleCoursePreviewClick = () => {
        // navigate("/stepThreePreview");
    }

    const handleSubmit = (data: IStepFour) => {
        // console.log("Dados a enviar: ", data);
        // console.log("Dados no formik: ", formik);
        if(formik?.dirty) {
            editStepFour(data, courseCode, handleCallbackSubmit);
        } else {
            handleCallbackSubmit()
        }
    };

    const switchOrSubmitStep4 = (newTargetStep: number|undefined) => {
        if(newTargetStep) {
            setTargetStep(newTargetStep);
        }
        formik.submitForm();
    }

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        // initialValues: dataStepFour as IStepFour
        initialValues: {
            ...dataStepFour,
            // Este campo deve por padrão vir com valor inicial 2 do backend
            idTipoDeModuloSelecionado: dataStepFour?.idTipoDeModuloSelecionado || '2'
        } as IStepFour
    });

    useImperativeHandle(ref, () => ({ switchOrSubmitStep4 }));

    useEffect(() => document?.getElementById('content')?.scrollTo({top: 0}),[]);

    useEffect(() => {
        const hasOnlyOneModuleSelected = formik?.values?.modulos?.filter(x => x.selecionada === true).length === 1;
        
        if(hasOnlyOneModuleSelected) setCanEditModule(true);
        else setCanEditModule(false);
    },[formik?.values?.modulos]);

    useEffect(() => {
        fetchStepFour(courseCode);
        setStepValues(dataStepFour);
        // if(type === 'edit') fetchStepFour(courseCode);
    }, [courseCode]);

    // useEffect(() => {
    //     return () => {
    //         formik.resetForm();
    //         resetCourseEditing();
    //     }
    // }, []);

    return (
        <Container className={className}>
            <Title>Passo 4: Estrutura do Curso</Title>
            <Text>Indicação de quantos módulos terá o seu curso e quais os seus títulos. O termo módulo é apenas uma sugestão. Você pode organizar o seu curso por semanas, aulas, etc.</Text>
            <Text>Para iniciar, clique em adicionar módulos. Você também poderá fazer uma descrição de cada módulo, para apresentá-lo aos seus alunos. A qualquer momento você poderá reordenar a sequência dos módulos inseridos e excluí-los.</Text>

            <ModuleChoice>
                <InputSelect
                    formik={formik}
                    options={courseModuleTypes.map((x: CourseModuleType) => {
                        return {
                            ...x,
                            value: x.value.toString(),
                        }
                    })}
                    label="Tipo de Estrutura do Curso *"
                    name="idTipoDeModuloSelecionado"
                    className="module-type"
                    readOnly={loading}
                    errorText={formik?.errors?.idTipoDeModuloSelecionado}
                    disabled={isEditingModule || loading || type === 'read'}
                />
                {formik?.values?.idTipoDeModuloSelecionado.toString() === "4" && (
                    <Input
                        label="Outro Tipo de Estrutura do Curso"
                        name="tipoDeModuloOutro"
                        placeholder="Digite um Tipo de Estrutura customizado"
                        className="module-type-others"
                        value={formik?.values?.tipoDeModuloOutro}
                        onChange={formik?.handleChange}
                        errorText={formik?.errors?.tipoDeModuloOutro}
                        disabled={loading}
                        maxLength={20}
                    />
                )}
                {type !== 'read' && (
                    <div className="module-type-controls">
                        <Button
                            btnTheme="primary"
                            onClick={handleAddCourseModuleClick}
                            disabled={loading || isEditingModule || isAddingModule}
                        >
                            Adicionar Módulo
                        </Button>
                        <Button
                            btnTheme="primary"
                            onClick={handleSortCourseModuleClick}
                            disabled={
                                loading || isEditingModule || 
                                formik?.values?.modulos?.length === 0 ||
                                formik?.values?.modulos.some(x => x.ordem === "")
                            }
                        >
                            Ordenar
                        </Button>
                        <Button
                            btnTheme="primary"
                            onClick={handleRemoveCourseModulesClick}
                            disabled={loading || isEditingModule || formik?.values?.modulos?.length === 0}
                        >
                            Excluir
                        </Button>
                        <Button
                            btnTheme="primary"
                            onClick={handleEditCourseModulesClick}
                            disabled={loading || !canEditModule || isEditingModule || isAddingModule}
                        >
                            Editar
                        </Button>
                    </div>
                )}

                <form id="hook-form" onSubmit={formik.handleSubmit}>
                    {loadingModulesList ? (
                        <SpinnerScreen/>
                    ) : (
                        formik?.values?.modulos?.length > 0 ? (
                            formik?.values?.modulos.map((module: Module, idx) => {
                                return (
                                    <div key={idx}>
                                        <span>
                                            <input
                                                id={idx.toString()}
                                                type="checkbox"
                                                onChange={
                                                    type !== 'read'
                                                    ?
                                                    (e: React.ChangeEvent<HTMLInputElement>) =>
                                                    formik?.setFieldValue(`modulos.${idx}.selecionada`, e.target.checked)
                                                    :
                                                    () => false
                                                }
                                                checked={
                                                    formik?.getFieldProps(`modulos.${idx}.selecionada`).value
                                                }
                                            />

                                        </span>
                                        <span>
                                            <label htmlFor={idx.toString()}>
                                                {`${
                                                    formik?.values?.idTipoDeModuloSelecionado.toString() === "4"
                                                    ? formik?.values?.tipoDeModuloOutro
                                                    : courseModuleTypes.find((x:CourseModuleType) => {
                                                        return x.value === Number(formik?.values?.idTipoDeModuloSelecionado)
                                                    })?.label || ' - '
                                                } ${module.nome}`}
                                            </label>
                                        </span>
                                        <span>
                                            <InputMasked
                                                name={formik?.getFieldProps(`modulos.${idx}`).value.nome}
                                                value={formik?.getFieldProps(`modulos.${idx}`).value.ordem}
                                                maskType="int1to99"
                                                maskPlaceholder=""
                                                onChange={
                                                    (e: React.ChangeEvent<HTMLInputElement>) => 
                                                    formik?.setFieldValue(`modulos.${idx}.ordem`, e.target.value)
                                                }
                                                disabled={type === 'read'}
                                                fit
                                            />
                                            {/* <InputNumber
                                                name={formik?.getFieldProps(`modulos.${idx}`).value.nome}
                                                value={formik?.getFieldProps(`modulos.${idx}`).value.ordem}
                                                maskType="int1to99"
                                                onChange={
                                                    (e: React.ChangeEvent<HTMLInputElement>) => 
                                                    formik?.setFieldValue(`modulos.${idx}.ordem`, e.target.value)
                                                }
                                                disabled={type === 'read'}
                                                fit
                                            /> */}
                                        </span>
                                    </div>
                                )
                            })
                        ) : (
                            <>
                                <p>Não existem módulos no momento.</p>
                                {/* <small>{'Obrigatório a inclusão de pelo menos um módulo.'}</small> */}
                            </>
                        )
                    )}
                </form>
            </ModuleChoice>

            { isAddingModule && (
                <section>
                    <AddCourseModule
                        courseCode={courseCode}
                        setAddOrEditModuleShow={setIsAddingModule}
                    />
                </section>
            )}

            { isEditingModule && (
                <section>
                    <EditCourseModule
                        editingModule={
                            formik.values.modulos.find(x => x.selecionada === true)
                        }
                        setAddOrEditModuleShow={setIsEditingModule}
                        courseCode={courseCode}
                    />
                </section>
            )}            

            <Buttons>
                <Button type="button" btnTheme="secondary" onClick={handleBackClick}>
                    Anterior
                </Button>
                <Button
                    type="button"
                    btnTheme="primary"
                    onClick={handleCoursePreviewClick}
                    disabled={checkDisabledSubmit()}
                >
                    Pré-visualizar Curso
                </Button>
                <Button
                    type="submit"
                    form="hook-form"
                    btnTheme="primary"
                    disabled={checkDisabledSubmit()}
                >
                    {formik?.isSubmitting && loading ? <Spinner /> : formik.dirty ? 'Salvar Passo 4' : 'Próximo'}
                </Button>
            </Buttons>            
        </Container>
    )
});

export default StepFour;