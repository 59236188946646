import * as yup from 'yup';

import { setLocale } from 'yup';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
  visivelParaAlunos: yup.boolean().required().label('Visível Aos Alunos'),
  nome: yup.string().min(3, 'Mínimo de 3 caracteres').max(50, 'Máximo de 50 caracteres').required().label('Nome do Módulo'),
  descricao: yup.string().min(3, 'Mínimo de 3 caracteres').max(255, 'Máximo de 255 caracteres').required().label('Descrição do Módulo'),
});

export default schema;
