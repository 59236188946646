import styled from "styled-components";

export const Container = styled.div`
    form {
        display: flex;
        flex-direction: column;

        section {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 32px;
            margin-bottom: 32px;

            > div {
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    > div {
                        display: flex;
                        gap: 16px;
                    }
                }
            }

            label {
                font-weight: bold;
            }
        }
    }    
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;