import * as yup from 'yup';

import { setLocale } from 'yup';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
  idTipoDeModuloSelecionado: yup.string()
    .required()
    .label('Tipo de Estrutura do Curso'),
  tipoDeModuloOutro: yup.string()
    .max(20, 'Máximo de 20 caracteres')
    .when('idTipoDeModuloSelecionado', {
      is: '4',
      then: yup.string().required(),
      otherwise: yup.string()
  })
});

export default schema;
