import { useState } from "react";
import { useFormik } from "formik";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import InputTextarea from "../../../../components/InputTextarea";
import Spinner from "../../../../components/Spinner";
import Info from "../../../../components/Info";
import { TYPE_QUESTION } from "../../../../shared/consts";
import { useCourse } from "../../../../shared/contexts/CourseContext";
import { useLoader } from "../../../../shared/contexts/LoadingContext";
import { Alternative, Question } from "../../../../shared/models/course/Question";
import schema from "./schema";
import { Buttons, Container, QuestionType } from "./styles";

interface Props {
    type: "edit" | "create" | "read";
    courseCode: number;
}

const NewQuestion: React.FC<Props> = ({type = "create", courseCode = 0}) => {
    const { loading } = useLoader();
    const {
        createQuestionForCurrentUserCourses,
        fetchQuestionsFromCurrentUser,
    } = useCourse();
    const [ typeQuestion, setTypeQuestion ] = useState<string>('0');
    const [ alternatives, setAlternatives ] = useState<Alternative[]>([
        {
            id: 1,
            texto: '',
            alternativaCorreta: false,
        },
        {
            id: 2,
            texto: '',
            alternativaCorreta: false,
        },
        {
            id: 3,
            texto: '',
            alternativaCorreta: false,
        },
        {
            id: 4,
            texto: '',
            alternativaCorreta: false,
        },
    ]);

    const getErrorTextMultiplaEscolhaUnica = (idx: number): string | undefined => {        
        let form = JSON.parse(JSON.stringify(formik));
        let errors = (form?.errors?.valorTipoMultiplaEscolhaUnica?.length > 0 &&
                        form?.errors?.valorTipoMultiplaEscolhaUnica[idx] != null)
            ? form.errors.valorTipoMultiplaEscolhaUnica[idx].texto
            : undefined;
        let touched = (form?.touched?.valorTipoMultiplaEscolhaUnica?.length > 0 &&
                        form?.errors?.valorTipoMultiplaEscolhaUnica &&
                        form?.errors?.valorTipoMultiplaEscolhaUnica[idx] != null)
            ? form.touched.valorTipoMultiplaEscolhaUnica[idx].texto
            : undefined;
        return touched && errors ? errors : undefined;
    }

    const getErrorTextMultiplaEscolhaMultipla = (idx: number): string | undefined => {        
        let form = JSON.parse(JSON.stringify(formik));
        let errors = (form?.errors?.valorTipoMultiplaEscolhaMultipla?.length > 0 &&
                  form?.errors?.valorTipoMultiplaEscolhaMultipla[idx] != null)
            ? form.errors.valorTipoMultiplaEscolhaMultipla[idx].texto
            : undefined;
        let touched = (form?.touched?.valorTipoMultiplaEscolhaMultipla?.length > 0 &&
                        form?.errors?.valorTipoMultiplaEscolhaMultipla &&
                        form?.touched?.valorTipoMultiplaEscolhaMultipla[idx] != null)
            ? form.touched.valorTipoMultiplaEscolhaMultipla[idx].texto
            : undefined;
        return touched && errors ? errors : undefined;
    }

    const handleAddAlternativeClick = () => {
        setAlternatives([
            ...alternatives,
            {
                id: alternatives.length + 1,
                texto: '',
                alternativaCorreta: false,
            }
        ]);
    }

    const handleQuestionTypeRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        // console.log(value);

        if(value === "0") { // Discursiva
            formik.setFieldValue('valorTipoSimNao', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaUnica', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaMultipla', undefined);
            setTypeQuestion(value);
        }
        if(value === "1") { // Alternativas Sim e Não
            formik.setFieldValue('valorTipoDiscursiva', undefined);
            formik.setFieldValue('valorTipoSimNao', false);
            formik.setFieldValue('valorTipoMultiplaEscolhaUnica', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaMultipla', undefined);
            setTypeQuestion(value);
        }        
        if(value === "2") { // Múltipla escolha de seleção única
            formik.setFieldValue('valorTipoDiscursiva', undefined);
            formik.setFieldValue('valorTipoSimNao', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaUnica', [
                {
                    id: 1,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 2,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 3,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 4,
                    texto: '',
                    alternativaCorreta: false,
                },
            ]);
            formik.setFieldValue('valorTipoMultiplaEscolhaMultipla', undefined);
            setTypeQuestion(value);
        }
        if(value === "3") { // Múltipla escolha de múltipla seleção
            formik.setFieldValue('valorTipoDiscursiva', undefined);
            formik.setFieldValue('valorTipoSimNao', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaUnica', undefined);
            formik.setFieldValue('valorTipoMultiplaEscolhaMultipla', [
                {
                    id: 1,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 2,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 3,
                    texto: '',
                    alternativaCorreta: false,
                },
                {
                    id: 4,
                    texto: '',
                    alternativaCorreta: false,
                },
            ]);
            setTypeQuestion(value);
        }        

        formik.setFieldValue(name, value);
    }

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const resetForm = () => {
        // if(type === "create") {
        //     fetchQuestionsByCourseId(currentCourseId);
        // }
        // if(type === "edit") {
        //     fetchQuestionsByCourseId(courseCode);
        // }
        fetchQuestionsFromCurrentUser();
        formik.resetForm();
    }

    const handleSubmit = (data: Question) => {
        // console.log("Cadastrando nova pergunta (form): ", formik);
        // TODO: verificar necessidade da condicional em função do type
        if(type === "create") {
            createQuestionForCurrentUserCourses(data, resetForm);
        }
        if(type === "edit") {
            createQuestionForCurrentUserCourses(data, resetForm);
        }
    }

    const getInitialValues = (): Question => {

        if(typeQuestion === '1') { // Alternativas Sim e Não
            return {
                enunciado: '',
                nome: '',
                selecionada: true,
                tipo: typeQuestion,
                perguntaPadrao: false,
                valorTipoDiscursiva: undefined,
                valorTipoSimNao: false,
                valorTipoMultiplaEscolhaUnica: undefined,
                valorTipoMultiplaEscolhaMultipla: undefined,
            } as Question;
        }

        if(typeQuestion === '2') { // Múltipla escolha de seleção única
            return {
                enunciado: '',
                nome: '',
                selecionada: true,
                tipo: typeQuestion,
                perguntaPadrao: false,
                valorTipoDiscursiva: undefined,
                valorTipoSimNao: undefined,
                valorTipoMultiplaEscolhaUnica: alternatives,
                valorTipoMultiplaEscolhaMultipla: undefined,
            } as Question;
        }

        if(typeQuestion === '3') { // Múltipla escolha de múltipla seleção
            return {
                enunciado: '',
                nome: '',
                selecionada: true,
                tipo: typeQuestion,
                perguntaPadrao: false,
                valorTipoDiscursiva: undefined,
                valorTipoSimNao: undefined,
                valorTipoMultiplaEscolhaUnica: undefined,
                valorTipoMultiplaEscolhaMultipla: alternatives,
            } as Question;
        }

        return { // Discursiva
            enunciado: '',
            nome: '',
            selecionada: true,
            tipo: typeQuestion,
            perguntaPadrao: false,
            valorTipoDiscursiva: '',
            valorTipoSimNao: undefined,
            valorTipoMultiplaEscolhaUnica: undefined,
            valorTipoMultiplaEscolhaMultipla: undefined,
        } as Question;        
    }
    const initialValues: Question = getInitialValues();
    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: schema,
        initialValues: initialValues,
    });

    return (
        <Container className="new-question">
            <form onSubmit={formik.handleSubmit}>
                <h2>Adicionar novas perguntas</h2>
                <section>
                    <InputTextarea
                        label="Pergunta"
                        id="enunciado"
                        name="enunciado"
                        placeholder="Escreva uma pergunta"
                        value={formik?.values?.enunciado}
                        onChange={formik?.handleChange}
                        errorText={formik?.errors?.enunciado}
                        readOnly={loading}
                    />
                    <QuestionType>
                        <label>Tipo de pergunta</label>
                        <div>
                            <input
                                type="radio"
                                id="discursiva"
                                name="tipo"
                                value={TYPE_QUESTION[0].value}
                                onChange={handleQuestionTypeRadioChange}
                                defaultChecked={typeQuestion === TYPE_QUESTION[0].value}
                            />
                            <label htmlFor="discursiva">{TYPE_QUESTION[0].label}</label>
                            <Info text="A resposta deve ser dada em forma de texto discursivo."/>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="simNao"
                                name="tipo"
                                value={TYPE_QUESTION[1].value}
                                onChange={handleQuestionTypeRadioChange}
                                defaultChecked={typeQuestion === TYPE_QUESTION[1].value}
                            />
                            <label htmlFor="simNao">{TYPE_QUESTION[1].label}</label>
                            <Info text="Questão com resposta binária Sim ou Não."/>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="multiplaEscolhaUnica"
                                name="tipo"
                                value={TYPE_QUESTION[2].value}
                                onChange={handleQuestionTypeRadioChange}
                                defaultChecked={typeQuestion === TYPE_QUESTION[2].value}
                            />
                            <label htmlFor="multiplaEscolhaUnica">{TYPE_QUESTION[2].label}</label>
                            <Info text="A questão pode ter duas ou mais alternativas e somente uma alternativa correta."/>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="multiplaEscolhaMultipla"
                                name="tipo"
                                value={TYPE_QUESTION[3].value}
                                onChange={handleQuestionTypeRadioChange}
                                defaultChecked={typeQuestion === TYPE_QUESTION[3].value}
                            />
                            <label htmlFor="multiplaEscolhaMultipla">{TYPE_QUESTION[3].label}</label>
                            <Info text="A questão pode ter duas ou mais alternativas e uma ou mais alternativas corretas."/>
                        </div>
                    </QuestionType>                    
                    {/* <InputSelect
                        label="Tipo de Pergunta"
                        id="tipo"
                        name="tipo"
                        formik={formik}
                        value={formik?.values?.tipo}
                        options={TYPE_QUESTION}
                        defaultValue={TYPE_QUESTION[2]}
                        errorText={formik?.errors?.tipo}
                        className="question-type"
                    /> */}

                    {/* { formik?.values?.tipo === "0" && (
                        <InputTextarea
                            label="Texto"
                            name="valorTipoDiscursiva"
                            type="textarea"
                            value={formik?.values?.valorTipoDiscursiva}
                            onChange={formik?.handleChange}
                            errorText={formik?.errors?.valorTipoDiscursiva}
                            readOnly={loading}
                        />
                    )} */}
                    { formik?.values?.tipo === "2" && (
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Alternativas</td>
                                        {/* <td><FaCheck title="Marque a alternativa que será considerada a correta"/></td> */}
                                    </tr>
                                </thead>
                                <tbody>
                                { formik?.values?.valorTipoMultiplaEscolhaUnica && 
                                    formik.values.valorTipoMultiplaEscolhaUnica.length > 0 &&
                                    formik.values.valorTipoMultiplaEscolhaUnica.map(((alternativa, idx) => {
                                    // const fieldName = `valorTipoMultiplaEscolhaUnica.${idx}`;
                                    const fieldName = `valorTipoMultiplaEscolhaUnica[${idx}]`;
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Input
                                                    id={fieldName}
                                                    name={fieldName}
                                                    placeholder="Digite a alternativa"
                                                    value={formik?.getFieldProps(`${fieldName}.texto`).value || ''}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        formik?.setFieldValue(`${fieldName}.texto`, e.target.value)
                                                    }
                                                    errorText={getErrorTextMultiplaEscolhaUnica(idx)}
                                                    // readOnly={loading}
                                                    //disabled={true}
                                                />
                                            </td>
                                            {/* <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) =>
                                                            formik?.setFieldValue(`valorTipoMultiplaEscolhaUnica.${idx}.alternativaCorreta`, e.target.checked)
                                                    }
                                                    checked={
                                                        formik?.getFieldProps(`valorTipoMultiplaEscolhaUnica.${idx}.alternativaCorreta`).checked
                                                    }
                                                />
                                            </td> */}
                                        </tr>
                                    )
                                }))}
                                </tbody>                                
                            </table>
                        </div>
                    )}

                    { formik?.values?.tipo === "3" && (
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Alternativas</td>
                                    </tr>
                                </thead>
                                <tbody>
                                { formik?.values?.valorTipoMultiplaEscolhaMultipla && 
                                    formik.values.valorTipoMultiplaEscolhaMultipla.length > 0 &&
                                    formik.values.valorTipoMultiplaEscolhaMultipla.map(((alternativa, idx) => {
                                    const fieldName = `valorTipoMultiplaEscolhaMultipla[${idx}]`;
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Input
                                                    id={fieldName}
                                                    name={fieldName}
                                                    placeholder="Digite a alternativa"
                                                    value={formik?.getFieldProps(`${fieldName}.texto`).value || ''}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        formik?.setFieldValue(`${fieldName}.texto`, e.target.value)
                                                    }
                                                    errorText={getErrorTextMultiplaEscolhaMultipla(idx)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }))}
                                </tbody>                                
                            </table>
                        </div>
                    )}                    
                </section>
                <Buttons>
                    <Button
                        type="button"
                        btnTheme="primary"
                        onClick={handleAddAlternativeClick}
                        disabled={formik?.values?.tipo === "0" || formik?.values?.tipo === "1"}
                    >
                        Adicionar alternativa
                    </Button>                        
                    <Button
                        type="submit"
                        btnTheme="primary"
                        disabled={checkDisabledSubmit()}
                    >
                        {loading ? <Spinner /> : 'Salvar pergunta'}
                    </Button>                        
                </Buttons>                
            </form>
        </Container>
    )
}
export default NewQuestion;