import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    line-height: 1.4;

    span {
        display: flex;
        margin-right: 8px;
        gap: 8px;
        color: ${props => props.theme.colors.labelText};

        &:not(span:first-child):before {
            content: '> ';
        }        
    }
    
    /* @media (max-width: 576px) {
        margin-bottom: 40px;
    } */
`;