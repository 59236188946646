import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import logo from '../../assets/img/logo_constructore.jpg';
import logoHighContrast from '../../assets/img/logo_constructore_branco.png';
import logoUFRJ from '../../assets/img/logo_ufrj.png';
import logoUFRJHighContrast from '../../assets/img/logo_ufrj_branco.png';
import logoLTC from '../../assets/img/logo_ltc.png';
import logoLTCHighContrast from '../../assets/img/logo_ltc_branco.png';
import logoMec from '../../assets/img/logo_mec.png';
import logoMecHighContrast from '../../assets/img/logo_mec_branco.png';
import logoNUTES from '../../assets/img/logo_nutes.png';
import logoNUTESHighContrast from '../../assets/img/logo_nutes_branco.png';
import logoFAPERJ from '../../assets/img/logo_faperj.jpg';
import logoFAPERJHighContrast from '../../assets/img/logo_faperj_branco.png';

import AccessibilityToolbar from '../../components/AccessibilityToolbar';
import Input from '../../components/Input';
import PublicMenu from '../../components/PublicMenu';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../shared/contexts/Auth';
import { useLoader } from '../../shared/contexts/LoadingContext';
import { useAccessibility } from '../../shared/contexts/AccessibilityContext';
import { LoginData } from '../../shared/models/Auth';
import schema from './schema';
import {
    Container,
    Logo,
    Form,
    TopBrand,
    Infos,
    ForgottenPassword,
    NotRegistered,
    FooterBrand,
    Content,
    Buttons
} from './styles';

const SignIn: React.FC = () => {
    // const [user, setUser] = useState<string>('');
    // const [password, setPassword] = useState<string>('');
    const { signIn } = useAuth();
    const { loading } = useLoader();
    const { theme } = useAccessibility();

    const handleSubmit = (data: LoginData) => {
        signIn(data.user, data.password);
    }

    const formik = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        initialValues: {
            user: '',
            password: '',
        },
    });

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || 
                (formik.dirty && !formik.isValid) ||
                loading
    }

    // function handleShowError(field: string) {
    //     return formik.touched?[field] && formik?.errors[field];
    // };
    const getErrorMessage = (fieldName: string) => {
        return (formik?.getFieldMeta(fieldName)?.touched &&
                formik?.getFieldMeta(fieldName)?.error)
            ?
            formik.getFieldMeta(fieldName).error
            :
            ''
    }

    const getLogoImage = (normal: string, highContrast: string) => {
        return theme.id !== 2 ? normal : highContrast;
    }

    return (
        <Container className="aux-sign-in">
            <AccessibilityToolbar />
            <Content>
                <TopBrand>
                    <span>
                        <img
                            src={getLogoImage(logoMec, logoMecHighContrast)}
                            alt="Ministério da Educação - MEC"
                            className="logo-mec"
                        />
                    </span>
                    <span>
                        <span>
                            <img
                                src={getLogoImage(logoUFRJ, logoUFRJHighContrast)}
                                alt="UFRJ"
                                className="logo-ufrj"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoNUTES,logoNUTESHighContrast)}
                                alt="NUTES"
                                className="logo-nutes"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoLTC,logoLTCHighContrast)}
                                alt="Laboratorio de Tecnologias Cognitivas"
                                className="logo-ltc"
                            />
                        </span>
                    </span>
                </TopBrand>
                <Infos>
                    <span><Link to="#">Notícias</Link></span>|
                    <span><Link to="#">Informações</Link></span>|
                    <span><Link to="#">Fale conosco</Link></span>|
                    <span>
                        <a href="https://google.com.br" target="_blank" 
                            rel="noopener noreferrer">
                            Busca
                        </a>
                    </span>
                </Infos>
                <Logo>
                    <img src={getLogoImage(logo, logoHighContrast)} alt="Logotipo Constructore" />
                </Logo>
                <Form onSubmit={formik.handleSubmit}>
                    <div>
                        <Input
                            label="Usuário"
                            type="text"
                            id="user"
                            name="user"
                            placeholder="Digite seu nome de usuário"
                            value={formik?.values?.user}
                            onChange={formik?.handleChange}
                            autoComplete
                            autoFocus
                            readOnly={loading}
                            errorText={getErrorMessage('user')}
                            fit
                        />
                        <Input
                            label="Senha"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Digite sua senha"
                            value={formik?.values?.password}
                            readOnly={loading}
                            onChange={formik?.handleChange}
                            errorText={getErrorMessage('password')}
                            // errorText={formik?.errors?.password}
                            fit
                        />
                        <ForgottenPassword>
                            <Link to='/forgotpassword'>Esqueci minha senha</Link>
                        </ForgottenPassword>
                        <Buttons>
                            <Button
                                type="submit"
                                disabled={checkDisabledSubmit()}
                                btnTheme="primary"
                            >
                                {loading ? <Spinner /> : 'Entrar'}
                            </Button>
                        </Buttons>
                    </div>
                    <NotRegistered>
                        <p>Não está cadastrado? Clique para se <Link to='/register'> cadastrar</Link></p>
                    </NotRegistered>
                </Form>
            </Content>
            <FooterBrand className="footer-brand">
                <img src={getLogoImage(logoFAPERJ, logoFAPERJHighContrast)} alt="Logotipo FAPERJ" />
            </FooterBrand>
            <PublicMenu />
        </Container>
    );
}

export default SignIn;