import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../shared/contexts/UserContext";
import { UpdatePasswordData } from "../../../shared/models/Users";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Title from "../../../components/Title";
import Breadcrumb from "../../../components/Breadcrumb";
import schema from "./schema";
import { Buttons, Container } from "./styles";

const UpdatePassword: React.FC = () => {
    const {
        updatePassword,
        isLoading,
    } = useUser();
    const { id } = useParams();
    const navigate = useNavigate();

    const handleCallbackSubmit = () => {
        navigate('/mydata')
    }

    const handleSubmit = (data: UpdatePasswordData) => {
        updatePassword(data, handleCallbackSubmit);
    };

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            userId: Number(id),
            confirmNewPassword: '',
            currentPassword: '',
            newPassword: '',
        } as UpdatePasswordData,
    });

    const handleCancelClick = () => {
        navigate('/mydata');
    }

    return (
        <Container>
            <Breadcrumb>
                <span><Link to="/mydata">Meus Dados</Link></span>
                <span>Alterar Senha</span>
            </Breadcrumb>
            <Title>Alterar Senha</Title>
            <section>
                <form onSubmit={formik.handleSubmit}>
                    <div>                        
                        <Input
                            label="Senha Atual *"
                            id="currentPassword"
                            name="currentPassword"
                            type="password"
                            placeholder="Digite a senha usada atualmente"
                            value={formik?.values?.currentPassword}
                            onChange={formik?.handleChange}
                            errorText={formik?.errors?.currentPassword}
                        />
                        <i />
                        <Input
                            label="Nova Senha *"
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            placeholder="Digite a nova senha"
                            value={formik?.values?.newPassword}
                            onChange={formik?.handleChange}
                            errorText={formik?.errors?.newPassword}
                        />
                        <Input
                            label="Confirme a Nova Senha *"
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            type="password"
                            placeholder="Confirme a nova senha"
                            value={formik?.values?.confirmNewPassword}
                            onChange={formik?.handleChange}
                            errorText={formik?.errors?.confirmNewPassword}
                        />                        
                    </div>
                    <Buttons>
                        <Button btnTheme="secondary" onClick={handleCancelClick}>
                            Cancelar
                        </Button>
                        <Button type="submit" btnTheme="primary" disabled={isLoading || !formik?.dirty}>
                            Salvar
                        </Button>
                    </Buttons>
                </form>
            </section>
        </Container>
    )
}
export default UpdatePassword;