/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    id: 1,
    title: 'light',
    colors: {
        primary: '#720E12',
        secondary: '#949494',
        tertiary: '#CCC',

        bgPrimary: '#fff',
        bgSecondary: '#F5F5F5',
        bgTopTabs: '#F2F2F2', // gray6

        labelText: '#000',
        placeholderText: '#949494', // secondary
        placeholderSearchText: '#949494',
        titleText: '#4F4F4F', // gray2
        linkText: '#720E12',
        menuElements: '#141414',
        tableDividerLine: 'rgba(0,0,0,.12)',
        mobilePullerSecondaryMenuIcon: '#4F4F4F',
        mobilePullerSecondaryMenuLabel: '#720E12',
        bgMenuElements: '#E0E0E0', // gray5
        bgAccessibilityToolbar: '#720E12',

        white: '#FFF',
        black: '#000',
        gray: '#BFBFBF',

        success: '#03BB85',
        info: '#3B5998',
        warning: '#FF6961',
    },
};