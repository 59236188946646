import { ButtonHTMLAttributes } from "react";
import { Container } from "./styles";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    btnTheme: 'primary' | 'secondary' | 'link';
}

const Button: React.FC<Props> = ({ ...rest }) => (
    <Container {...rest} />
);

export default Button;