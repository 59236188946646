import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/contexts/Auth";
import { useUser } from "../../shared/contexts/UserContext";
import { useMenu } from "../../shared/contexts/MenuContext";
import { useDropdown } from "../../shared/contexts/domains/DropdownContext";
import { useLoader } from "../../shared/contexts/LoadingContext";
import { AuthUser } from "../../shared/models/Auth";
import { User } from "../../shared/models/Users";
import { DomainRegion, DomainSelectOption } from "../../shared/models/domains/Domains";
import { LinkTypeEnum } from "../../shared/enums/Domains";
import { LINK_TYPE } from "../../shared/consts";
import { SingleValue } from "react-select";
import InputSelectNoFormik from "../../components/InputSelectNoFormik";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputDateHTML from "../../components/InputDateHTML";
import InputSelect from "../../components/InputSelect";
import InputSingleFileUploadPure from "../../components/InputSingleFileUploadPure";
import InputMasked from "../../components/InputMasked";
import Title from "../../components/Title";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import schema from "./schema";
import { Feed, UpdatePasswordWrap } from "./styles";
import { Buttons, Container } from "./styles";

interface  RefObject {
    clearField: () =>  void;
}

const MyData: React.FC = () => {
    const  cityFieldRef = useRef<RefObject>(null)
    const { loading } = useLoader();
    const {
        userEditing,
        getUserById,
        editUser, 
        hasCompleteRegistration,
        isLoading,
        clearUserEditing,
    } = useUser();
    const { logged, getStorageAuhtenticatedUser } = useAuth();
    const { trackMenuOption } = useMenu();
    const { regions } = useDropdown();
    const navigate = useNavigate();
    const [filteredCities, setFilteredCities] = useState<DomainSelectOption[]>([]);
    const mounted = useRef(true);
    const customId = "invalid-form-fields";

    const  clearField = () => {
        if (cityFieldRef.current) {
            cityFieldRef.current.clearField();
        }
    }

    const handleSubmit = (data: User) => {
        editUser(data, '/');
        // console.log("Dirty?: ", formik.dirty);
        // console.log("Formik: ", formik);
        // console.log(data);
    };

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: userEditing as User,
    });

    const handleCancelClick = () => {
        navigate('/');
    }

    const getRegionUFs = () => {
        const ufs: DomainSelectOption[] = regions.map(x => {
            return {
                value: x.codigo.toString(),
                label: x.sigla,
                alt: x.nome
            }
        });

        return ufs;
    }

    const getErrorMessage = (fieldName: string): string | undefined => {
        if(formik.isSubmitting && !formik.isValid){
            toast.error("Verifique os campos obrigatórios.", {
                toastId: customId
            });
        }

        return (formik?.getFieldMeta(fieldName)?.touched &&
                formik?.getFieldMeta(fieldName)?.error)
            ?
            formik.getFieldMeta(fieldName).error
            :
            ''
    }

    // const getRegionUFs = useMemo(() => {
    //     const ufs: DomainSelectOption[] = regions.map(x => {
    //         return {
    //             value: x.codigo.toString(),
    //             label: x.sigla,
    //         }
    //     });

    //     return ufs;
    // },[regions]);

    // const handleSelected = (date: Date) => {
    //     const dateStr = date.toISOString();
    //     setSelectedDate(dateStr);
    // }
    // const handleDateChange = (fieldName: string, date: Date) => {
    //     const dateStr = date.toISOString();
    //     formik.setFieldValue(fieldName, dateStr);
    //     setSelectedDate(dateStr);
    // }

    // const getCitiesCallback = useCallback (
    //     () => {
    //         const selectedUF = formik?.values?.estado;
    //         const cities:any = selectedUF ? regions?.find((x: DomainRegion) => {
    //             if(x.codigo.toString() === selectedUF) {
    //                 return x.cidades;
    //             }
    //             return false;
    //         }) : [];

    //         let aux = [];
    //         if(cities?.cidades) {
    //             aux = cities.cidades.map((city:any) => {
    //                 return {
    //                     value: city.codigo.toString(),
    //                     label: city.nome,
    //                 } as DomainSelectOption;
    //             });
    //         }
            
    //         setFilteredCities(aux);
    //     },[formik?.values?.estado, regions]
    // );

    useEffect(() => {
        trackMenuOption(2);
    }, [trackMenuOption]);

    useEffect(() => {
        if(logged) {
            let userAuthenticated: AuthUser = getStorageAuhtenticatedUser();
            if(!userAuthenticated?.id) return;
            getUserById(userAuthenticated.id);
        }
    },[logged]);

    useEffect(() => {
        // const selectedUF = userEditing?.estado;
        const selectedUF = formik?.values?.estado;
        const cities: any = selectedUF || selectedUF === ''
            ?
            regions?.find((x: DomainRegion) => x.codigo.toString() === selectedUF)?.cidades || []
            :
            [];

        let aux = [];
        if(cities) {
            aux = cities.map((city:any) => {
                return {
                    value: city.codigo.toString(),
                    label: city.nome,
                } as DomainSelectOption;
            });
        }
        
        setFilteredCities(aux);

    },[formik?.values?.estado, regions]);

    // useEffect(() => {
    //     getCitiesCallback()
    // },[getCitiesCallback]);

    useEffect(() => {
        return () => clearUserEditing();
    },[]);

    return (
        <Container>
            <Breadcrumb>
                <span>Meus Dados</span>
            </Breadcrumb>
            
            <Title>Meus Dados</Title>

            {!hasCompleteRegistration && (
                <Feed>
                    <FaExclamationCircle />
                    <p>Você precisa preencher seus dados antes de poder utilizar o sistema por completo!</p>
                </Feed>
            )}

            <section>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row-1">
                        <div>
                            <Input
                                label="Nome Completo *"
                                id="nome"
                                name="nome"
                                placeholder="Digite seu nome completo"
                                value={formik?.values?.nome}
                                onChange={formik?.handleChange}
                                errorText={getErrorMessage('nome')}
                            />
                            <div>
                                <InputDateHTML
                                    label="Data de Nascimento *"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    value={formik?.values?.dataNascimento || ''}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('dataNascimento')}
                                />
                                {/* <InputDateNoFormik
                                    label="* Data de Nascimento"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    // selected={selectedDate && selectedDate!=='' ? new Date(selectedDate) : undefined}
                                    selected={selectedDate}
                                    onSelect={date => handleSelected(date)}
                                    onChange={date => handleDateChange('dataNascimento', date)}
                                    noDefaultDate
                                /> */}
                                <InputSelect
                                    formik={formik}
                                    options={[
                                        { value: '1', label: 'Masculino' },
                                        { value: '2', label: 'Feminino' },
                                        { value: '3', label: 'Não Informado' },
                                    ]}
                                    label="Sexo *"
                                    name="sexo"
                                    errorText={getErrorMessage('sexo')}
                                />
                            </div>
                            <div>
                                {/* <Input
                                    label="CPF *"
                                    id="cpf"
                                    name="cpf"
                                    placeholder="00000000000"
                                    value={formik?.values?.cpf}
                                    onChange={formik?.handleChange}
                                    errorText={
                                        formik?.touched?.cpf && formik?.errors?.cpf
                                        ? formik?.errors?.cpf
                                        : undefined                                      
                                    }
                                /> */}
                                <InputMasked
                                    maskType='cpf'
                                    label="CPF *"
                                    id="cpf"
                                    name="cpf"
                                    placeholder="000.000.000-00"
                                    value={formik?.values?.cpf}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik?.setFieldValue('cpf', e.target.value)
                                    }
                                    errorText={getErrorMessage('cpf')}
                                />
                                <Input
                                    label="E-mail *"
                                    id="email"
                                    name="email"
                                    placeholder="Digite seu E-mail"
                                    value={formik?.values?.email}                                
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('email')}
                                />
                            </div>
                            <div>
                                <InputSelect
                                    label="Telefone Visível"
                                    name="celularVisivel"
                                    formik={formik}
                                    options={[
                                        { value: '0', label: 'Não' },
                                        { value: '1', label: 'Sim' },
                                    ]}
                                    defaultValue={{ value: '1', label: 'Sim' }}
                                    value={formik?.values?.celularVisivel}
                                />
                                {/* <Input
                                    label="Celular"
                                    id="celular"
                                    name="celular"
                                    placeholder="00000000000"
                                    value={formik?.values?.celular}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.celular}
                                /> */}
                                <InputMasked
                                    maskType='cellPhone'
                                    label="Celular"
                                    id="celular"
                                    name="celular"
                                    placeholder="(00) 0 0000-0000"
                                    value={formik?.values?.celular}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik?.setFieldValue('celular', e.target.value)
                                    }
                                    errorText={getErrorMessage('celular')}
                                />
                            </div>
                        </div>
                        {mounted?.current === true
                            && userEditing
                            && (
                                <InputSingleFileUploadPure
                                    name="fotoPerfilServer"
                                    value={formik?.values?.fotoPerfilServer || undefined}
                                    formik={formik}
                                    maxSizeMB={1}
                                    extensions={{
                                        'image/jpeg': [],
                                        'image/png': [],
                                    } as any}
                                />
                                // <InputSingleFileUpload 
                                //     name="fotoPerfilServer"
                                //     formik={formik}
                                // />                            
                        )}
                        {/* <Photo>
                            <span>Foto do usuário</span>
                            {mounted?.current === true
                            && userEditing
                            && (
                                <InputSingleFileUpload
                                    name="fotoPerfilServer"
                                    formik={formik}
                                />
                            )}
                            <small>Tamanho máximo 5 MB. Formatos permitidos: .png, .jpg, .gif</small>
                        </Photo> */}                        
                    </div>
                    <div className="row-2">

                        <InputSelectNoFormik
                            name="estado"
                            label="UF *"
                            value={
                                formik?.values
                                ? 
                                getRegionUFs().find((x: DomainSelectOption) =>
                                    x?.value?.toString() === formik?.values['estado']?.toString()
                                )
                                :
                                undefined
                            }
                            onChange={(e:SingleValue<DomainSelectOption>) => {
                                formik.setFieldValue('estado', e?.value);
                                clearField();                                
                            }}
                            options={getRegionUFs()}
                            searchable
                            errorText={getErrorMessage('estado')}
                        />
                        <InputSelectNoFormik
                            ref={cityFieldRef}
                            name="cidade"
                            label="Cidade *"
                            value={
                                formik?.values
                                ?                                
                                filteredCities?.find((x: DomainSelectOption) => 
                                    x.value.toString() === formik?.values['cidade']?.toString()
                                )
                                :
                                undefined
                            }                            
                            options={filteredCities}
                            searchable
                            disabled={filteredCities?.length === 0}
                            errorText={getErrorMessage('cidade')}
                            onChange={(e:SingleValue<DomainSelectOption>) => {
                                formik.setFieldValue('cidade', e?.value);
                            }}
                        />

                        {/* <InputSelect
                            name="estado"
                            label="UF *"
                            formik={formik}
                            options={getRegionUFs()}
                            // options={getRegionUFs}
                            // dependentFieldName="cidade"
                            searchable
                            errorText={getErrorMessage('estado')}
                            // onChange={(e:SingleValue<DomainSelectOption>) => {
                            //     formik.setFieldValue('estado', e?.value);
                            //     formik.setFieldValue('cidade', '');
                            //     // formik.setFieldValue('estado', formik?.initialValues['estado']);
                            //     // formik.setFieldValue('cidade', '');
                            //     // alert();
                            // }}
                            // value={
                            //     formik?.values
                            //     ? 
                            //     getRegionUFs.find((x: DomainSelectOption) =>
                            //         x?.value?.toString() === formik?.values['cidade']?.toString()
                            //         // x.value.toString() === formik.values[`${name}`].toString()
                            //         // x.value.toString() === formik.getFieldProps(name).value.toString()
                            //     )
                            //     :
                            //     undefined
                            // }
                        />
                        <InputSelect
                            label="Cidade *"
                            name="cidade"
                            formik={formik}
                            options={filteredCities}
                            searchable
                            disabled={filteredCities?.length === 0}
                            errorText={getErrorMessage('cidade')}
                            // onChange={(e:SingleValue<DomainSelectOption>) => {
                            //     formik.setFieldValue('cidade', e?.value);
                            //     // formik.setFieldValue('cidade', '');
                            //     // formik.setFieldValue('estado', '');
                            //     // alert();
                            // }}
                            // value={
                            //     filteredCities?.find((x: DomainSelectOption) => 
                            //         x.value.toString() === formik?.values['cidade']?.toString()
                            //         // x.value.toString() === formik.values[`${name}`].toString()
                            //         // x.value.toString() === formik.getFieldProps(name).value.toString()
                            //     )
                            // }
                        /> */}

                        <InputSelect
                            label="Vinculado à UFRJ"
                            name="vinculadoUFRJ"
                            formik={formik}
                            options={[
                                { value: '0', label: 'Não' },
                                { value: '1', label: 'Sim' },
                            ]}
                            defaultValue={{ value: '0', label: 'Não' }}
                            errorText={getErrorMessage('vinculadoUFRJ')}
                        />
                        
                        {formik?.values?.vinculadoUFRJ === '1' && (
                            <InputSelect
                                label="Tipo de Vínculo"
                                name="tipoVinculo"
                                formik={formik}
                                options={LINK_TYPE}
                                defaultValue={LINK_TYPE[0]}
                                errorText={getErrorMessage('tipoVinculo')}
                                // errorText={
                                //     formik?.touched?.tipoVinculo && formik?.errors?.tipoVinculo
                                //     ? formik?.errors?.tipoVinculo
                                //     : undefined
                                // }
                            />
                        )}

                        {formik?.values?.vinculadoUFRJ === '1' 
                            && formik?.values?.tipoVinculo === LinkTypeEnum.TEACHER && (
                            <>
                                <Input
                                    label="SIAPE *"
                                    id="siape"
                                    name="siape"
                                    placeholder="Digite o SIAPE"
                                    value={formik?.values?.siape}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('siape')}
                                />
                                <Input
                                    label="Centro"
                                    id="centro"
                                    name="centro"
                                    placeholder="Digite o Centro"
                                    value={formik?.values?.centro}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('centro')}
                                />
                                <Input
                                    label="Instituição de Ensino"
                                    id="instituicao"
                                    name="instituicao"
                                    placeholder="Instituto, Faculdade, Escolas, Hospitais e Museus"
                                    value={formik?.values?.instituicao}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('instituicao')}
                                />
                                <Input
                                    label="Departamento"
                                    id="departamento"
                                    name="departamento"
                                    placeholder="Digite seu Departamento"
                                    value={formik?.values?.departamento}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.departamento}
                                />
                                <Input
                                    label="Outros"
                                    id="outros"
                                    name="outros"
                                    placeholder="Outros"
                                    // value={formik?.values?.outros}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.outros}
                                />
                            </>
                        )}

                        {formik?.values?.vinculadoUFRJ === '1' 
                            && formik?.values?.tipoVinculo === LinkTypeEnum.STUDENT && (
                            <>
                                <Input
                                    label="DRE *"
                                    id="dre"
                                    name="dre"
                                    placeholder="Digite seu DRE"
                                    value={formik?.values?.dre}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('dre')}
                                />
                                <Input
                                    label="Curso"
                                    id="curso"
                                    name="curso"
                                    placeholder="Digite seu Curso"
                                    // value={formik?.values?.curso}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.curso}
                                />
                                <Input
                                    label="Nível"
                                    id="nivel"
                                    name="nivel"
                                    placeholder="Digite o Nível"
                                    // value={formik?.values?.nivel}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.nivel}
                                />
                                <Input
                                    label="Centro"
                                    id="centro"
                                    name="centro"
                                    placeholder="Digite o Centro"
                                    value={formik?.values?.centro}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('centro')}
                                />
                                <Input
                                    label="Departamento"
                                    id="departamento"
                                    name="departamento"
                                    placeholder="Digite seu Departamento"
                                    value={formik?.values?.departamento}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.departamento}
                                />
                                <Input
                                    label="Outros"
                                    id="outros"
                                    name="outros"
                                    placeholder="Outros"
                                    // value={formik?.values?.outros}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.outros}
                                />
                            </>
                        )}
                        
                        <UpdatePasswordWrap>
                            <label>&nbsp;</label>
                            <div>
                                <Link to={`/updatepassword/${userEditing?.id}`}>Alterar senha</Link>
                            </div>
                            <small></small>
                        </UpdatePasswordWrap>
                        {/* <Button btnTheme="secondary" onClick={handleUpdatePassword}>Alterar senha</Button> */}
                    </div>
                    <Buttons>
                        {hasCompleteRegistration && (
                            <Button  btnTheme="primary" onClick={handleCancelClick}>
                                Cancelar
                            </Button>
                        )}
                        <Button type="submit" btnTheme="primary" disabled={isLoading || !formik?.dirty}>
                            {loading ? <Spinner /> : 'Salvar'}
                        </Button>
                    </Buttons>
                </form>
            </section>
        </Container>
    )
}
export default MyData;