import { useEffect, useState, Ref, forwardRef, useImperativeHandle } from "react";
import { useFormik } from "formik";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { useLoader } from "../../../shared/contexts/LoadingContext";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { IStepTwo } from "../../../shared/models/course/Course";
import { Question } from "../../../shared/models/course/Question";
import { Buttons, Container, GenericTableStyled } from "./styles";
import { Text } from "./styles";
import Title from "../../../components/Title";
import { TYPE_QUESTION } from "../../../shared/consts";
import NewQuestion from "./NewQuestion";

interface RefObject {
    switchOrSubmitStep2: (newTargetStep: number|undefined) => void;
}
interface IStep2 {
    setStep: (step: number) => void;
    className?: string;
    type: 'create' | 'edit' | 'read';
    courseCode: number;
    ref: Ref<RefObject>
}

const StepTwo = forwardRef((
    {
        setStep,
        className,
        type,
        courseCode = 0,
    }: IStep2,
    ref: Ref<RefObject>
) => {
    const [targetStep, setTargetStep] = useState<number|undefined>(undefined);
    const { loading } = useLoader();
    const {
        questions,
        selectedQuestionsInCurrentCourse,
        resetCourseEditing,
        saveStepTwo,
        editStepTwo,
        fetchQuestionsFromCurrentUser,
        fetchQuestionsByCourseId,
    } = useCourse();

    const handleQuestionCheckboxChange = (item: Question, checked: boolean) => {
        // console.log("item antes: ", item);
        // console.log("checked? ", checked);
        // console.log("questions: ", questions);
        // console.log("formik inicial: ", formik.values.perguntasIds);
        const hasQuestionRecord = formik?.values?.perguntasIds.some((questionId:number) => questionId === item?.id);
        // const hasQuestionRecord = questions?.some((question:Question) => question.id === item?.id?.toString());
        if(checked === false && hasQuestionRecord) {
            // se desmarcou a pergunta na tela e ela existe na base, então remove do formik
            const filteredArray = formik?.values?.perguntasIds?.filter(questionId => {
                return questionId !== item?.id;
            });
            formik.setFieldValue('perguntasIds', filteredArray);
        }
        if(checked === true && !hasQuestionRecord) {
            // se selecionou a pergunta na tela e ela não existe na base, então adiciona no formik
            formik.setFieldValue('perguntasIds', [...formik?.values?.perguntasIds, item?.id]);
        }
        // console.log("formik final: ", formik.values.perguntasIds);
    }

    const handleBackClick = () => {
        setStep(1);
    };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleCallbackSubmit = () => {
        if(targetStep) {
            setStep(targetStep);
            // setTargetStep(undefined);
        }
        else {
            setStep(3)
        }        
    }

    const handleCoursePreviewClick = () => {
        // navigate("/stepOnePreview");
    }

    const switchOrSubmitStep2 = (newTargetStep: number|undefined) => {
        if(newTargetStep) {
            setTargetStep(newTargetStep);
        }
        formik.submitForm();
    }

    const handleSubmit = (data: IStepTwo) => {
        // console.log("Código do curso: ", courseCode);
        // console.log("Submissão: ", data);
        if(type === 'create') {
            saveStepTwo(data, courseCode, handleCallbackSubmit);
            console.log("Criação");
        }
        if(type === 'edit') {
            if(formik.dirty) {
                editStepTwo(data, courseCode, handleCallbackSubmit);
            } else {
                handleCallbackSubmit();
            }
        }
        if(type === 'read') handleCallbackSubmit();
    };

    // const getQuestionsIds = (): string[] => {
    //     return questions.map((question: any) => {
    //         return question.id.toString()
    //     });
    // }
    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        // validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            perguntasIds: (type === "edit" || type === "read")
                ?
                selectedQuestionsInCurrentCourse.map((x:Question) => {
                    return x.id
                })
                :
                []
        } as IStepTwo,     
    });

    const text =
    `O formulário de inscrição só poderá ser alterado até o inicio do período de matrícula de seu curso.
    Você pode selecionar algumas das perguntas previamente inseridas abaixo, inserir no final da página ou ir para o Passo 3.
    Lembre-se, as ações feitas no formulário de inscrição devem ser salvas para que tenham efeito.`;

    useImperativeHandle(ref, () => ({ switchOrSubmitStep2 }));

    useEffect(() => document?.getElementById('content')?.scrollTo({top: 0}),[]);

    useEffect(() => {
        // if(type === 'edit') fetchQuestionsByCourseId(courseCode);
        /*if(type === 'edit')*/ fetchQuestionsFromCurrentUser();
        if(type === 'edit' || type === 'read') fetchQuestionsByCourseId(courseCode);
    }, [courseCode, type]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            resetCourseEditing();
        }
    }, []);

    return (
        <Container className={className}>
            <Title>Passo 2: Formulário de Inscrição do Curso</Title>
            <Text>{text}</Text>

            <section>
                <h2>Selecionar e adicionar perguntas</h2>
                <form id="hook-form" onSubmit={formik.handleSubmit}>
                    <div className="row-1">
                        <h4>Perguntas padrão</h4>
                        {questions?.length > 0 ? (
                            <GenericTableStyled>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Selecionar pergunta</th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {questions.filter(x => x.perguntaPadrao === true).map((question, idx) => (
                                    <tr key={question.id}>
                                        <td>
                                            <input
                                                id={`default-questions-${idx}`}
                                                type="checkbox"
                                                onChange={
                                                    type !== 'read'
                                                    ?
                                                    (e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleQuestionCheckboxChange(question, e.target.checked)
                                                    :
                                                    () => false
                                                }
                                                checked={
                                                    formik?.values?.perguntasIds?.some(
                                                        (id: number) => id === question?.id
                                                    ) || false
                                                }
                                            />
                                        </td>
                                        <td><label htmlFor={`default-questions-${idx}`}>{question.enunciado}</label></td>
                                        <td>{TYPE_QUESTION.find(x => x.value === question.tipo)?.label}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </GenericTableStyled>
                        ) : (
                            <p>Não há perguntas padrão.</p>
                        )}                     
                    </div>
                    <br /><br /><br />
                    <div>
                        <h4>Perguntas já usadas em seus cursos</h4>
                        {questions?.length > 0 ? (
                            <GenericTableStyled>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Selecionar pergunta</th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {questions.filter(x => x.perguntaPadrao === false).map((question, idx) => (
                                    <tr key={question.id}>
                                        <td>
                                            <input
                                                id={`used-questions-${idx}`}
                                                type="checkbox"
                                                onChange={
                                                    type !== 'read'
                                                    ?
                                                    (e: React.ChangeEvent<HTMLInputElement>) =>
                                                    handleQuestionCheckboxChange(question, e.target.checked)
                                                    :
                                                    () => false
                                                }
                                                checked={
                                                    formik?.values?.perguntasIds?.some(
                                                        (id: number) => id === question?.id
                                                    ) || false
                                                }
                                            />
                                        </td>
                                        <td><label htmlFor={`used-questions-${idx}`}>{question.enunciado}</label></td>
                                        <td>{TYPE_QUESTION.find(x => x.value === question.tipo)?.label}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </GenericTableStyled>
                        ) : (
                            <p>Não há perguntas utilizadas em outros cursos seus.</p>
                        )}
                    </div>
                </form>
            </section>

            {type !== 'read' && <NewQuestion type={type} courseCode={courseCode}/>}

            <Buttons>
                <Button type="button" btnTheme="secondary" onClick={handleBackClick}>
                    Anterior
                </Button>
                <Button
                    type="button"
                    btnTheme="primary"
                    onClick={handleCoursePreviewClick}
                    disabled={checkDisabledSubmit()}
                >
                    Pré-visualizar Curso
                </Button>                        
                <Button
                    type="submit"
                    form="hook-form"
                    btnTheme="primary"
                    disabled={checkDisabledSubmit()}
                >
                    {loading ? <Spinner /> : formik.dirty ? 'Salvar Passo 2' : 'Próximo'}
                </Button>
            </Buttons>            
        </Container>
    )
});

export default StepTwo;