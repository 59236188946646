import { useEffect, useState, Ref, forwardRef, useImperativeHandle } from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { useLoader } from "../../../shared/contexts/LoadingContext";
import Title from "../../../components/Title";
import Info from "../../../components/Info";
import { useFormik } from "formik";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { ICourseRecourses, IStepThree } from "../../../shared/models/course/Course";
import { Buttons, Container, Text } from "./styles";

interface RefObject {
    switchOrSubmitStep3: (newTargetStep: number|undefined) => void;
}
interface IStep3 {
    setStep: (step: number) => void;
    className?: string;
    type: 'create' | 'edit' | 'read';
    courseCode: number;
    ref: Ref<RefObject>
}

const StepThree = forwardRef((
    {
        setStep,
        className,
        type,
        courseCode = 0
    }: IStep3,
    ref: Ref<RefObject>
) => {
    const [targetStep, setTargetStep] = useState<number|undefined>(undefined);
    const {
        loadingFetch,
        resources,
        resetCourseEditing,
        fetchStepThree,
        saveStepThree,
    } = useCourse();
    const { loading } = useLoader();

    const handleBackClick = () => {
        setStep(2);
    };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleCallbackSubmit = () => {
        if(targetStep) {
            setStep(targetStep);
            // setTargetStep(undefined);
        }
        else {
            setStep(4)
        }        
    }

    const handleCoursePreviewClick = () => {
        // navigate("/stepThreePreview");
    }

    const switchOrSubmitStep3 = (newTargetStep: number|undefined) => {
        if(newTargetStep) {
            setTargetStep(newTargetStep);
        }
        formik.submitForm();
    }

    const handleSubmit = (data: IStepThree) => {
        // console.log("Dados a enviar: ", data);
        // console.log("Dados no formik: ", formik);
        if(formik.dirty) {
            saveStepThree(data, courseCode, handleCallbackSubmit);
        } else {
            handleCallbackSubmit();
        }
    };

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        enableReinitialize: true,
        initialValues: resources as IStepThree
    });

    const text = `Não se preocupe, as informações poderão ser alteradas a qualquer momento. Selecione os recursos que você deseja oferecer aos alunos.`;

    useImperativeHandle(ref, () => ({ switchOrSubmitStep3 }));

    useEffect(() => document?.getElementById('content')?.scrollTo({top: 0}),[]);

    useEffect(() => {
        fetchStepThree(courseCode);
    }, [courseCode, type]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            resetCourseEditing();
        }
    }, []);

    return (
        <Container className={className}>
            <Title>Passo 3: Recursos de Consulta e de Comunicação</Title>
            <Text>{text}</Text>
            <br />
            <form onSubmit={formik.handleSubmit}>
                <h2>Recursos disponíveis</h2>
                <section>
                    <div>
                        <p>Consulta</p>
                        {loadingFetch ? (
                            <Spinner/>
                        ) : (
                            formik?.values?.consulta?.length > 0 ? (
                                formik.values.consulta.map((resourse:ICourseRecourses, idx) => {
                                    const nameItem = `consulta-${idx}`;
                                    return (
                                        <div key={idx}>
                                            <input
                                                id={nameItem}
                                                type="checkbox"
                                                onChange={
                                                    type !== 'read'
                                                    ?
                                                    (e: React.ChangeEvent<HTMLInputElement>) =>
                                                    formik?.setFieldValue(`consulta.${idx}.selecionada`, e.target.checked)
                                                    :
                                                    () => false
                                                }
                                                checked={
                                                    formik?.getFieldProps(`consulta.${idx}.selecionada`).value
                                                }
                                                disabled={loading}
                                            />
                                            <label htmlFor={nameItem}>{resourse.nome}</label>
                                            <Info text={resourse.nome}/>
                                        </div>
                                    )
                                })
                            ) : (
                                <p>Não existem recursos no momento.</p>
                            )
                        )}
                    </div>
                    <div>
                        <p>Comunicação</p>
                        {loadingFetch ? (
                            <Spinner/>
                        ) : (
                            formik?.values?.comunicacao?.length > 0 ? (
                                formik?.values?.comunicacao?.map((resourse:ICourseRecourses, idx) => {
                                    const nameItem = `comunicacao-${idx}`;
                                    return (
                                        <div key={idx}>
                                            <input
                                                id={nameItem}
                                                type="checkbox"
                                                onChange={
                                                    type !== 'read'
                                                    ? 
                                                    (e: React.ChangeEvent<HTMLInputElement>) =>
                                                    formik?.setFieldValue(`comunicacao.${idx}.selecionada`, e.target.checked)
                                                    :
                                                    () => false
                                                }
                                                checked={
                                                    formik?.getFieldProps(`comunicacao.${idx}.selecionada`).value
                                                }
                                                disabled={loading}
                                            />
                                            <label htmlFor={nameItem}>{resourse.nome}</label>
                                            <Info text={resourse.nome}/>                                        
                                        </div>
                                    )
                                })
                            ) : (
                                <p>Não existem recursos no momento.</p>
                            )
                        )}
                    </div>
                </section>

                <Buttons>
                    <Button type="button" btnTheme="secondary" onClick={handleBackClick}>
                        Anterior
                    </Button>
                    <Button
                        type="button"
                        btnTheme="primary"
                        onClick={handleCoursePreviewClick}
                        disabled={checkDisabledSubmit()}
                    >
                        Pré-visualizar Curso
                    </Button>                        
                    <Button
                        type="submit"
                        btnTheme="primary"
                        disabled={checkDisabledSubmit()}
                    >
                        {loading ? <Spinner /> : formik.dirty ? 'Salvar Passo 3' : 'Próximo'}
                    </Button>                        
                </Buttons>
            </form>
        </Container>
    )
});

export default StepThree;