import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { Reset } from './Reset';

export interface FontSize {
    fontSize: number;
}

export const GlobalStyled = createGlobalStyle<FontSize>`
    ${Reset}

    :root {
        /* --primary: #5B0C0F; */
        --primary: #720E12;
        --primary-variation2: #702a2d;
        --primary-variation3: #84494b;
        --primary-variation4: #986769;
        --primary-variation5: #ad8687;
        --primary-variation6: #c1a4a5;
        --primary-variation7: #d6c2c3;
        --primary-variation8: #eae1e1;

        --secondary: #949494;
        --secondary-orange: #F8A427;

        --gray3: #828282;
        --gray2: #4F4F4F;
        --gray5: #E0E0E0;
        --gray6: #F2F2F2;

        font-size: 16px;      
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }    

    html, body, #root {
        height: 100%;
        background-color: ${props => props.theme.colors.bgPrimary};
    }

    body {
        font-size: ${props => props.fontSize}rem;

        @media (max-width: 576px) {
            font-size: 0.875rem;
        }
    }

    *, button, input {
        border: 0;
        outline: 0;
        font-family: 'Verdana', 'Roboto', sans-serif !important;
    }

    button {
        cursor: pointer;

        &:focus {
            outline: 1px dotted var(--primary);
        }
    }

    a {
        font-weight: 400;
        text-decoration: ${props => props.theme.id === 2 ? 'underline' : 'none'};

        &:hover {
            text-decoration: underline;
        }

        &:link, &:hover, &:visited, &:active {
            color: ${props => props.theme.colors.linkText};
        }

        &:focus-visible {
            outline: 1px dotted var(--primary);
        }
    }

    h1, h2, h3, h4 {
        display: flex;
        font-weight: bold;
        color: ${props => props.theme.colors.titleText};
    }

    h1 {
        /* font-size: 2rem; // 32px */
        /* font-size: 1.875rem; //30px */
        font-size: 1.5rem; // 24px
        
        /* @media (max-width: 576px) {
            // font-size: 1.625rem; // 26px
            font-size: 1.5rem; // 24px
        } */
    }

    h2 {
        /* font-size: 1.5rem; // 24px */
        /* font-size: 1.625rem; // 26px */
        font-size: 1.375rem; // 22px

        @media (max-width: 576px) {
            // font-size: 1.375rem; // 22px
            font-size: 1.3125rem; // 21px
        }

        margin-bottom: 32px;
    }

    h3 {
        font-size: 1.125rem; // 18px
        margin-bottom: 8px;
    }

    h4 {
        /* font-size: 1rem; // 16px */
        margin-bottom: 8px;
    }

    h5 {
        text-align: left;
        margin-bottom: 24px;
    }    

    small {
        font-size: 0.7rem
    }

    label, pre {
        color: ${props => props.theme.colors.labelText};
    }

    input {
        &::placeholder {
            color: var(--secondary);
        }
    }

    p {
        color: ${props => props.theme.colors.labelText};
        text-align: left;
    }

    /* .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Content {
        width: 40%;
        height: 50%;
        margin: auto auto;
        box-shadow: 0px 0px 8px 0px #a1a1a1;
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;

        ul {
            li {
                list-style: none;
            }
        }

        footer {
            small {
                display: flex;
                justify-content: end;
            }
        }
    } */

    .react-confirm-alert-overlay {
        background-color: #727171de;
    }

    @media (max-width: 992px) {
        span#vlibras {
            display: none;
        }

    }

    .Toastify__toast-theme--colored.Toastify__toast--success {
        background: #347831;
    }
`;

export default GlobalStyled;