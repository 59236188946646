import { Container } from "./styles";

interface Props {
    children?: React.ReactNode | React.ReactNode[];
}

const Title: React.FC<Props> = ({children}) => {
    return (
        <Container>
            <h1>{children}</h1>
        </Container>
    )
}
export default Title;