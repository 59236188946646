import styled from "styled-components";

export const Container = styled.div`
    section {
        display: flex;
        flex-direction: column;
        
        form {
            .row-1 {
                display: flex;
                flex-direction: row;
                column-gap: 32px;
                margin-bottom: 16px;

                @media (max-width: 761px) {
                    flex-direction: column;
                    row-gap: 32px;
                }

                > div:first-child {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 8px;
                    /* place-content: end; */
                    /* background: red; */

                    > div:nth-child(2),
                    > div:nth-child(3),
                    > div:nth-child(4) {
                        display: flex;
                        flex-direction: row;
                        column-gap: 32px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;

                        // @media (max-width: 576px) {
                        // @media (max-width: 768px) {
                        @media (max-width: 1021px) { // em função do campo "Data de Nascimento"
                            grid-template-columns: 1fr;
                        }
                    }
                }

                > div:last-child {
                    @media (max-width: 761px) {
                        align-self: center;
                    }
                }                
            }

            .row-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 32px;
                row-gap: 8px;

                @media (max-width: 768px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
`;

export const Photo = styled.div`
    width: 247px;
    min-width: 247px;
    height: 256px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > span {
        align-self: start;
    }

    > small {
        color: var(--secondary);
    }
`;

export const Preview = styled.div`
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    position: relative;

    aside {
        display: 'flex';
        flex-direction: 'row';
        flex-wrap: 'wrap';
        margin-top: 16;
        min-width: 100%;

        img {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }
    }

    div {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background-color: #ff000042; */

        &:hover {
            cursor: pointer;
        }

        p {
            font-size: 12px;
            color: var(--secondary);
            background: green;
            background: transparent;
        }
    }   
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;

export const InputSelectStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* min-width: 150px; */

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        flex-direction: row;
    }

    .react-select-container {
        align-content: 'flex-start';
        /* width: 153px; */
    }
`;

export const Feed = styled.div`
    display: flex;
    flex-direction: row;
    margin: 32px 0;
    color: var(--secondary-orange);
    border: 1px solid var(--secondary-orange);
    padding: 16px;

    svg {
        margin-right: 16px;
    }

    p {
        color: var(--secondary-orange);
    }
`;

export const UpdatePasswordWrap = styled.div`
    display: flex;
    flex-direction: column;

    > label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        align-self: center;
        justify-content: center;
        height: 37px;
        min-height: 32px;
        width: 100%;
        border: dashed #949494 1px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
    }

    > small {
        margin-top: 2px;
        min-height: 15px;
    }
`;