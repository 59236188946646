import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgotPassword from '../pages/ForgotPassword';
import Register from '../pages/Register';

import SignIn from '../pages/SignIn';

const AuthRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<SignIn/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forgotpassword" element={<ForgotPassword/>} />
    </Routes>
);

export default AuthRoutes;