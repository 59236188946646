import { useFormik } from "formik";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import InputTextarea from "../../../../components/InputTextarea";
import Spinner from "../../../../components/Spinner";
import InputBinarySelect from "../../../../components/InputBinarySelect";
import { Module } from "../../../../shared/models/course/Module";
import { useLoader } from "../../../../shared/contexts/LoadingContext";
import { useCourse } from "../../../../shared/contexts/CourseContext";
import schema from "./schema";
import { Buttons, Container } from "./styles";

interface Props {
    courseCode: number;
    setAddOrEditModuleShow?: (value: boolean) => void;
}

const AddCourseModule: React.FC<Props> = ({courseCode, setAddOrEditModuleShow = () => {} }) => {
    const { loading } = useLoader();
    const { fetchStepFour, createModule } = useCourse();

    const resetForm = () => {
        fetchStepFour(courseCode);
        // formik.resetForm();
        setAddOrEditModuleShow(false);
    }

    const cancelEditModule = () => {
        setAddOrEditModuleShow(false);
    }

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleSubmit = (data: Module) => {
        console.log("Criando novo módulo (form): ", formik);
        createModule(data, courseCode, resetForm);
    }

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            id: 0,
            ordem: "",
            nome: "",
            visivelParaAlunos: false,
            descricao: "",
            selecionada: false,
        } as Module
    });

    return (
        <Container className="new-question">
            <form onSubmit={formik.handleSubmit}>
                <section>
                    <h2>Adicionar Módulo</h2>              
                    <InputBinarySelect
                        formik={formik}
                        label="Visível Para Alunos"
                        name="visivelParaAlunos"
                        value={formik?.values?.visivelParaAlunos}
                        className="visible-to-student"
                        disabled={formik?.isSubmitting}
                    />
                    <Input
                        label="Nome do Módulo"
                        id="nome"
                        name="nome"
                        placeholder="Digite a descrição do módulo"
                        value={formik?.values?.nome}
                        onChange={formik?.handleChange}
                        readOnly={formik?.isSubmitting}
                        errorText={formik?.errors?.nome}
                    />
                    <InputTextarea
                        label="Descrição do Módulo"
                        id="descricao"
                        name="descricao"
                        placeholder="Escreva a descrição do módulo"
                        value={formik?.values?.descricao}
                        onChange={formik?.handleChange}
                        readOnly={formik?.isSubmitting}
                        errorText={formik?.errors?.descricao}
                    />
                </section>
                <Buttons>                       
                    <Button
                        type="submit"
                        btnTheme="primary"
                        disabled={checkDisabledSubmit()}
                    >
                        { loading ? <Spinner /> : 'Criar Módulo' }
                    </Button>
                    <Button
                        btnTheme="secondary"
                        onClick={cancelEditModule}
                        disabled={checkDisabledSubmit()}
                    >
                        Cancelar Criação do Módulo
                    </Button>
                </Buttons>                
            </form>
        </Container>
    )
}
export default AddCourseModule;