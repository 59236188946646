import styled from "styled-components";

export const Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (min-width: 577px) {
            .module-type,
            .module-type-others,
            .visible-to-student {
                width: 460px;
            }
        }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    line-height: 1.2;
`;

export const ModuleChoice = styled.section`

    .module-type-controls {
        display: flex;
        column-gap: 32px;

        @media (max-width: 576px) {
            justify-content: space-between;
            column-gap: 4px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        margin-top: 24px;
        width: 460px;

        @media (max-width: 992px) {
            width: auto;
        }        

        > div {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 16px;

            input {
                text-align: end;
                padding: 8px;
            }

            > span:nth-child(2) {
                width: 100%;
                text-align: start;
            }
            
            > span:last-child {
                width: 60px;
                min-width: 60px;
            }
        }
        
        small {
            margin-top: 2px;
            align-self: start;
            text-align: left;
            color: red;
            min-height: 15px;
        }
    }
`;