import { Link } from "react-router-dom";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaCalendarAlt, FaChalkboardTeacher, FaPencilRuler, FaServer, FaUserAlt, FaUserCog } from "react-icons/fa";
import { useMenu } from "../../shared/contexts/MenuContext";
import { Container, Puller, Options } from "./styles";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";

const Menu: React.FC = () => {
    const {
        menu,
        trackedOption,
        openOrCloseMenu,
        expanderManagementOption
    } = useMenu();
    const { hasCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();

    const handleClick = () => {
        openOrCloseMenu();
    };

    return (
        <Container
            className={'aux-menu ' + (!hasCompleteRegistration ? 'closed' : menu.isOpen ? 'open' : 'closed')}
            role="navigation"
        >
            <Options aria-label="Menu principal">
                {hasCompleteRegistration && (
                    <li className={trackedOption===1 ? 'active' : ''}>
                        <Link id="first" to='/mycourses' title="Meus cursos">
                            <div><FaChalkboardTeacher /><span>Meus cursos</span></div>
                        </Link>
                    </li>
                )}
                <li className={trackedOption===2 ? 'active' : ''}>
                    <Link to='/mydata' title="Meus dados">
                        <div><FaUserAlt /><span>Meus dados</span></div>
                    </Link>
                </li>
                {hasCompleteRegistration && (
                    <li className={trackedOption===3 ? 'active' : ''}>
                        <Link to='#' title="Agenda">
                            <div><FaCalendarAlt /><span>Agenda</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && hasPermission() && (
                    <li className={trackedOption===4 ? 'active' : ''}>
                        <Link to='/course' title="Criar curso" reloadDocument={true}>
                            <div><FaPencilRuler /><span>Criar curso</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li className={trackedOption===5 ? 'active' : ''}>
                        <Link to='#' title="Recursos">
                            <div><FaServer /><span>Recursos</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li /*className={trackedOption===6 ? 'active' : ''}*/>
                        <Link
                            to='#'
                            title="Administração"
                            onClick={e => expanderManagementOption()}
                            className={`option-parent ${trackedOption===6 ? 'active' : ''}`}
                            aria-label={`Administração sub-menu: ${!menu.options.isOpenManagement || !menu.isOpen ? 'fechado' : 'aberto'}`}
                        >
                            <div><FaUserCog /><span>Administração</span></div>
                        </Link>
                        <ul
                            className={`option-parent-list ${
                                !menu.options.isOpenManagement || !menu.isOpen ? 'closed' : ''
                            }`}
                        >
                            <li>Cursos</li>
                            <li>
                                <Link to='/Admin' title="Usuários" aria-label="Usuários">
                                    <span>Usuários</span>
                                </Link>
                            </li>
                            <li>Layout</li>
                            <li>Estatística</li>
                            <li>Grupos de áreas de conhecimento</li>
                            <li>Perguntas de inscrição</li>
                            <li>Suporte técnico</li>
                        </ul>
                    </li>
                )}
            </Options>
            { hasCompleteRegistration && (
                <Puller
                    onClick={() => handleClick()}
                    aria-label={menu.isOpen ? "Retrair menu" : "Expandir menu"}>
                    {menu.isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
                </Puller>
            )}
        </Container>
    )
};

export default Menu;