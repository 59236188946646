import { useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { useCourse } from "../../../shared/contexts/CourseContext";
import StepOne from "../StepOne";
import StepTwo from "../StepTwo";
import StepThree from "../StepThree";
import StepFour from "../StepFour";
import StepFive from "../StepFive";
import { Container, Steps } from "./styles";

const CourseCreate: React.FC = () => {
    const [ step, setStep ] = useState<number>(1);
    // const { codigo } = useParams();
    const {
        currentCourseId,
        // resetCourseEditing,
    } = useCourse();

    // const getCode = () => {
    //     return Number(codigo) || 0
    // }

    // useEffect(() => {
    //     return () => {
    //         setStep(0);
    //         resetCourseEditing();
    //     }
    // }, []);

    return (
        <Container>
            <Breadcrumb>
                <span>Criação de Curso</span>
            </Breadcrumb>
            <Steps>
                <i />
                <div className={`${step===1 ? 'active' : ''}`}><span>1</span></div>
                <div className={`${step===2 ? 'active' : ''}`}><span>2</span></div>
                <div className={`${step===3 ? 'active' : ''}`}><span>3</span></div>
                <div className={`${step===4 ? 'active' : ''}`}><span>4</span></div>
                <div className={`${step===5 ? 'active' : ''}`}><span>5</span></div>
            </Steps>
            <p></p>
            {step === 1 && (
                <StepOne
                    type={'create'}
                    setStep={setStep}
                    className={`${step===1 ? 'active' : ''}`}
                    courseCode={currentCourseId}
                />
            )}
            {step === 2 && (
                <StepTwo
                    type={'create'}
                    setStep={setStep}
                    className={`${step===2 ? 'active' : ''}`}
                    courseCode={currentCourseId}
                />
            )}
            {step === 3 && (
                <StepThree
                    type={'create'}
                    setStep={setStep}
                    className={`${step===3 ? 'active' : ''}`}
                    courseCode={currentCourseId}
                />
            )}
            {step === 4 && (
                <StepFour
                    type={'create'}
                    setStep={setStep}
                    className={`${step===4 ? 'active' : ''}`}
                    courseCode={currentCourseId}
                />
            )}
            {step === 5 && (
                <StepFive
                    type={'create'}
                    setStep={setStep}
                    className={`${step===5 ? 'active' : ''}`}
                    courseCode={currentCourseId}
                />
            )}            
        </Container>
    )
}
export default CourseCreate;