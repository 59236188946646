import styled from "styled-components";

export const Container = styled.div`
    display: none;

    &.active {
        display: block;
    }

    section {
        display: flex;
        flex-direction: column;
        
        form {
            .row-1 {
                display: flex;
                flex-direction: row;
                column-gap: 32px;
                margin-bottom: 16px;

                @media (max-width: 992px) {
                    flex-direction: column;
                    row-gap: 32px;

                    > div:last-child {
                        align-self: center;

                        > span {
                            align-self: center;
                        }
                    }
                }
                
                .row-1-1 {
                    width: 100%;
                    display: grid;
                    -webkit-column-gap: 32px;
                    column-gap: 32px;
                    row-gap: 8px;
                    /* background: #ff000063; */

                    .row-1-1-1 {
                        display: grid;
                        row-gap: 8px;
                    }

                    .row-1-1-2 {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 32px;
                        row-gap: 8px;
                        height: fit-content;
                        /* background: #73b973; */

                        // @media (max-width: 576px) {
                        // @media (max-width: 992px) {
                        @media (max-width: 1326px) { // em função do label "Área de conhecimento"
                            grid-template-columns: 1fr;
                        }
                    }
                }
            }

            .row-2 {
                display: grid;
                column-gap: 32px;
                row-gap: 8px;
                
                .row-2-1 {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 32px;
                    row-gap: 8px;

                    @media (max-width: 992px) {
                        grid-template-columns: 1fr;
                    }                    
                }
            }
        }
    }
`;

export const Photo = styled.div`
    width: 247px;
    min-width: 247px;
    height: 325px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > small {
        color: var(--secondary);
    }
`;

export const Preview = styled.div`
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    position: relative;

    aside {
        display: 'flex';
        flex-direction: 'row';
        flex-wrap: 'wrap';
        margin-top: 16;
        min-width: 100%;

        img {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }
    }

    div {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background-color: #ff000042; */

        &:hover {
            cursor: pointer;
        }

        p {
            font-size: 12px;
            color: var(--secondary);
            background: green;
            background: transparent;
        }
    }   
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;

export const InputSelectStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* min-width: 150px; */

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        flex-direction: row;
    }

    .react-select-container {
        align-content: 'flex-start';
    }
`;

export const Feed = styled.div`
    display: flex;
    flex-direction: row;
    margin: 32px 0;
    color: var(--secondary-orange);
    border: 1px solid var(--secondary-orange);
    padding: 16px;

    svg {
        margin-right: 16px;
    }
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;