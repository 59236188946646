import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout';
import Admin from '../pages/Admin';
import EditForm from '../pages/Admin/EditForm';
import AddForm from '../pages/Admin/AddForm';
import CourseCreate from '../pages/Course/CourseCreate';
import CourseEdit from '../pages/Course/CourseEdit';
import CourseRead from '../pages/Course/CourseRead';
import MyCourses from '../pages/MyCourses';
import Home from '../pages/Home';
import MyData from '../pages/MyData';
import { useDropdown } from '../shared/contexts/domains/DropdownContext';
import { useUser } from '../shared/contexts/UserContext';
import SiteMap from '../pages/SiteMap';
import Accessibility from '../pages/Accessibility';
import UpdatePassword from '../pages/MyData/UpdatePassword';
import { useAuth } from '../shared/contexts/Auth';

const AppRoutes: React.FC = () => {
    const { checkCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();
    const {
        fetchRegions,
        // fetchUFs, ufs,
        fetchCities,
        fetchKnowledgeAreas,
        fetchLevels,
        fetchCourseModuleTypes,
        fetchCourseModalities,
    } = useDropdown();

    useEffect(() => {
        checkCompleteRegistration();
    }, []);

    useEffect(() => {
        fetchRegions();
        // fetchUFs();
        fetchCities();
        fetchKnowledgeAreas();
        fetchLevels();
        fetchCourseModuleTypes();
        fetchCourseModalities();
    }, []);

    return (
        <Layout>
            {/* {
                !hasCompleteRegistration && authenticatedUser ? (
                    <Routes>
                        <Route path={`/admin/edit/${authenticatedUser?.id}`} element={<EditForm/>} />
                    </Routes>
                ):(
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/admin" element={<Admin/>} />
                        <Route path="/admin/edit/:id" element={<EditForm/>} />
                    </Routes>
                )
            } */}

            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/admin" element={<Admin/>} />
                <Route path="/admin/edit/:id" element={<EditForm/>} />
                <Route path="/mydata" element={<MyData/>} />
                {hasPermission() && <Route path="/course" element={<CourseCreate/>} />}
                {hasPermission() && <Route path="/course/edit/:codigo" element={<CourseEdit/>} />}
                {!hasPermission() && <Route path="/course/read/:codigo" element={<CourseRead/>} />}
                <Route path="/mycourses" element={<MyCourses/>} />
                <Route path="/sitemap" element={<SiteMap/>} />
                <Route path="/accessibility" element={<Accessibility/>} />
                <Route path="/updatepassword/:id" element={<UpdatePassword/>} />
                {hasPermission() && <Route path="/register" element={<AddForm/>} />}
                <Route path="*" element={<Navigate to={"/"}/>} />
            </Routes>
        </Layout>
    )
};

export default AppRoutes;