import * as yup from 'yup';

import { setLocale } from 'yup';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
  nome: yup.string().min(3, 'Mínimo de 3 caracteres').max(50, 'Máximo de 50 caracteres').required().label('Nome'),
  dataNascimento: yup.date().required().label('dataNascimento'),
  sexo: yup.string().matches(/[1-3]/).required().label('Sexo'),
  cpf: yup
    .string()
    .min(11, "O campo deve ter exatamente 11 números")
    .matches(
      /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
      "Campo com formato inválido"
    )
    .required()
    .label('CPF'),
  email: yup.string().email("O campo deve ser um e-mail válido").required().label('E-mail'),
  celular: yup
    .string()
    .min(11, "O campo deve ter exatamente 11 números")
    .label('Celular'),
  estado: yup.string().required().label('UF'),
  cidade: yup.string().required().label('Cidade'),
  tipoVinculo: yup.string().when('vinculadoUFRJ', {
    is: (vinculadoUFRJ: string) => vinculadoUFRJ === '1',
    then: yup.string().required(),
    otherwise: yup.string().optional()
  }),
  // siape: yup.string().label('SIAPE').when(['vinculadoUFRJ','tipoVinculo'], {
  //   is: (vinculadoUFRJ: string, tipoVinculo: string) => vinculadoUFRJ === '1' && tipoVinculo === '1',
  //   then: yup.string().required(),
  //   otherwise: yup.string().optional()
  // }),
  siape: yup.string().label('SIAPE').when(['vinculadoUFRJ','tipoVinculo'], {
    is: (vinculadoUFRJ: string, tipoVinculo: string) => vinculadoUFRJ === '1' && tipoVinculo === '1',
    then: yup.string().required(),
    otherwise: yup.string().optional()
  }),
  dre: yup.string().label('DRE').when(['vinculadoUFRJ','tipoVinculo'], {
    is: (vinculadoUFRJ: string, tipoVinculo: string) => vinculadoUFRJ === '1' && tipoVinculo === '0',
    then: yup.string().required(),
    otherwise: yup.string().optional()
  }),
  // password: yup.string().required().label('Senha'),
  // confirmacaoSenha: yup.string().required().label('Confirmação de Senha'),
});

export default schema;
