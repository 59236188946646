import api from './api';
import { Question } from '../models/course/Question';

const ENDPOINT = 'curso_perguntas';

export const questionService = {
    createQuestion,
    getQuestionsFromCurrentUser,
    // edit,
};

async function getQuestionsFromCurrentUser(): Promise<Question[]> {
    const { data } = await api.get(`${ENDPOINT}`);

    return data;
};

// async function associateQuestionWithCourse(question: Question, courseCode: number): Promise<any> {
//     const { data } = await api.post<any>(`${ENDPOINT}/${courseCode}`, question);

//     return data;
// };

async function createQuestion(question: Question): Promise<any> {
    const { data } = await api.post<any>(`${ENDPOINT}`, question);

    return data;
};

// async function edit(question: Question, courseCode: number): Promise<any> {
//     const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, question);

//     return data;
// };