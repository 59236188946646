import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('Campo obrigatório').label('E-mail'),
  cpf: yup
    .string()
    .min(11, "O campo deve ter exatamente 11 números")
    .matches(
      /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
      "Campo com formato inválido"
    )
    .required()
    .label('CPF'),
  password: yup.string().required('Campo obrigatório').label('Senha'),
});

export default schema;
