import { ICourse, IStepOne } from '../models/course/Course';
import api from './api';

const ENDPOINT = 'curso';

export const courseService = {
    list,
    getCourseById,
    create,
    edit,
};

async function list(): Promise<any[]> {
    const { data } = await api.get(`${ENDPOINT}`);

    /* mockup */
    // const data: any[] = [
    //     {
    //         id: 1,
    //         nome: "Anatomia Humana e Animal",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Online",
    //         publicoAlvo: "Aluno",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: true,
    //         periodos: 2
    //     },
    //     {
    //         id: 2,
    //         nome: "Bioestatística",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Presencial",
    //         publicoAlvo: "Professor",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: true,
    //         periodos: 2
    //     },
    //     {
    //         id: 3,
    //         nome: "Biologia Celular e Molecular",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Online",
    //         publicoAlvo: "Aluno",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: false,
    //         periodos: 2
    //     },
    //     {
    //         id: 4,
    //         nome: "Bioquímica",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Presencial",
    //         publicoAlvo: "Professor",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: true,
    //         periodos: 2
    //     },
    //     {
    //         id: 5,
    //         nome: "Biologia Celular e Molecular",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Presencial",
    //         publicoAlvo: "Aluno",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: false,
    //         periodos: 2
    //     },
    //     {
    //         id: 6,
    //         nome: "Anatomia Humana e Animal",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Online",
    //         publicoAlvo: "Professor",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: false,
    //         periodos: 2
    //     },
    //     {
    //         id: 7,
    //         nome: "Aprender com mapas mentais",
    //         areaConhecimento: "Biologia",
    //         modalidade: "Online",
    //         publicoAlvo: "Aluno",
    //         dataLiberacao: "01/07/2022",
    //         cursoAtualizado: false,
    //         periodos: 2
    //     }
    // ] as any;

    return data;
};

async function getCourseById(courseId: number): Promise<ICourse> {
    const { data } = await api.get(`${ENDPOINT}/${courseId}`);

    return data;
};

async function create(stepOne: IStepOne): Promise<any> {
    const { data } = await api.post<any>(`${ENDPOINT}`, stepOne);

    return data;
};

async function edit(stepOne: IStepOne, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, stepOne);

    return data;
};