import React from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import Breadcrumb from '../../../components/Breadcrumb';
import Input from '../../../components/Input';
import InputMasked from '../../../components/InputMasked';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { RegisterData } from '../../../shared/models/Auth';
import { useAuth } from '../../../shared/contexts/Auth';
import { useLoader } from '../../../shared/contexts/LoadingContext';
import schema from './schema';
import {
    Container,
    Form,
    Buttons,
    Content,
} from './styles';

const AddForm: React.FC = () => {
    const { addPartialNewUser } = useAuth();
    const { loading } = useLoader();
    const navigate = useNavigate();

    const handleSubmit = (data: RegisterData) => {
        data.username = data.email.split('@')[0];
        addPartialNewUser(data);
    }

    const formik = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            email: '',
            cpf: '',
            password: '',
            // perfil: [],
            username: ''
        } as RegisterData,
    });

    // function handleShowError(field: string) {
    //     return formik.touched?[field] && formik?.errors[field];
    // };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    return (
        <Container>
            <Breadcrumb>
                <span><Link to='/admin'>Usuários</Link></span>
                <span>Cadastro Parcial de Novo Usuário</span>
            </Breadcrumb>            
            <Content>
                <Title>Cadastro Parcial de Novo Usuário</Title>

                <Form onSubmit={formik.handleSubmit}>
                    <div>
                        <Input
                            label="E-mail *"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Digite seu e-mail"
                            value={formik?.values?.email}
                            onChange={formik?.handleChange}
                            autoComplete
                            autoFocus
                            readOnly={loading}
                            errorText={
                                formik?.touched?.email && formik?.errors?.email
                                ? formik?.errors?.email
                                : undefined
                            }
                        />
                        {/* <Input
                            label="CPF *"
                            type="text"
                            id="cpf"
                            name="cpf"
                            placeholder="Digite seu CPF"
                            value={formik?.values?.cpf}
                            onChange={formik?.handleChange}
                            autoComplete
                            readOnly={loading}
                            errorText={formik?.errors?.cpf}
                        /> */}
                        <InputMasked
                            maskType='cpf'
                            label="CPF *"
                            id="cpf"
                            name="cpf"
                            placeholder="Digite seu CPF"
                            value={formik?.values?.cpf}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                formik?.setFieldValue('cpf', e.target.value)
                            }
                            autoComplete
                            readOnly={loading}
                            errorText={
                                formik?.touched?.cpf && formik?.errors?.cpf
                                ? formik?.errors?.cpf
                                : undefined
                            }
                        />                     
                        <Input
                            label="Senha *"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Digite sua senha"
                            value={formik?.values?.password}
                            readOnly={loading}
                            onChange={formik?.handleChange}
                            errorText={
                                formik?.touched?.password && formik?.errors?.password
                                ? formik?.errors?.password
                                : undefined
                            }
                        />
                        <Buttons>
                            <Button btnTheme="secondary" onClick={() => navigate('/admin')}>
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                btnTheme="primary"
                                disabled={checkDisabledSubmit()}
                            >
                                {formik.isSubmitting || loading ? <Spinner /> : 'Salvar'}
                            </Button>
                        </Buttons>                        
                    </div>
                </Form>
            </Content>         
        </Container>
    );
}

export default AddForm;