import { useEffect, useState, Ref, forwardRef, useImperativeHandle } from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { useLoader } from "../../../shared/contexts/LoadingContext";
import Title from "../../../components/Title";
import { useFormik } from "formik";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { Buttons, Container, Text } from "./styles";
import { IStepFive } from "../../../shared/models/course/Course";
import { useUser } from "../../../shared/contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface RefObject {
    switchOrSubmitStep5: (newTargetStep: number|undefined) => void;
}
interface IStep5 {
    setStep: (step: number) => void;
    className?: string;
    type: 'create' | 'edit' | 'read';
    courseCode: number;
}

const StepFive = forwardRef((
    {
        setStep,
        className,
        type,
        courseCode = 0
    }: IStep5,
    ref: Ref<RefObject>
) => {
    const [targetStep, setTargetStep] = useState<number|undefined>(undefined);
    const {
        loadingFetch,
        dataStepFive,
        fetchStepFive,
        editStepFive,
    } = useCourse();
    const { loading } = useLoader();
    const { authenticatedUser } = useUser();
    const navigate = useNavigate();

    const handleBackClick = () => {
        setStep(4);
    };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleCallbackSubmit = () => {
        if(targetStep) {
            setStep(targetStep);
            // setTargetStep(undefined);
        }
        else {
            navigate('/');
        }        
    }

    const handleCoursePreviewClick = () => {
        // navigate("/stepFivePreview");
    }

    const switchOrSubmitStep5 = (newTargetStep: number|undefined) => {
        if(newTargetStep) {
            setTargetStep(newTargetStep);
        }
        formik.submitForm();
    }

    const handleSubmit = (data: IStepFive) => {
        // console.log("Dados a enviar: ", data);
        // console.log("Dados no formik: ", formik);
        if(formik.dirty) {
            editStepFive(data, courseCode, handleCallbackSubmit);
        } else {
            handleCallbackSubmit();
        }
    };

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        enableReinitialize: true,
        initialValues: dataStepFive as IStepFive
    });

    const text = `Selecionando as opções "Liberar curso", você irá liberar o seu curso para todos os usurários que estiverem cadastrados nele. Se ainda não quiser liberar o curso, não marque esta opção e clique em salvar.`;
    const text2 = "Marque as informações que devem ser liberadas para a visualização de seus alunos neste curso.";

    useImperativeHandle(ref, () => ({ switchOrSubmitStep5 }));

    useEffect(() => document?.getElementById('content')?.scrollTo({top: 0}),[]);

    useEffect(() => {
        fetchStepFive(courseCode);
    }, [courseCode, type]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            // resetCourseEditing();
        }
    }, []);

    return (
        <Container className={className}>
            <Title>Passo 5: Publicação do curso</Title>
            <Text>{text}</Text>
            <form onSubmit={formik.handleSubmit}>
                <section>
                    <div>
                        <h5>{text2}</h5>
                        {loadingFetch ? (
                            <Spinner/>
                        ) : (
                            <div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="liberadoVisualizacaoEmail"
                                        onChange={
                                            type !== 'read'
                                            ?
                                            (e: React.ChangeEvent<HTMLInputElement>) =>
                                            formik?.setFieldValue('liberadoVisualizacaoEmail.selecionada', e.target.checked)
                                            :
                                            () => false
                                        }
                                        checked={
                                            formik?.values?.liberadoVisualizacaoEmail?.selecionada || false
                                        }
                                        disabled={loading}
                                    />
                                    <label htmlFor="liberadoVisualizacaoEmail">{formik?.values?.liberadoVisualizacaoEmail?.valor}:</label>
                                    {authenticatedUser?.email}
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="liberadoVisualizacaoLocalidade"
                                        onChange={
                                            type !== 'read'
                                            ?
                                            (e: React.ChangeEvent<HTMLInputElement>) =>
                                            formik?.setFieldValue('liberadoVisualizacaoLocalidade.selecionada', e.target.checked)
                                            :
                                            () => false
                                        }
                                        checked={
                                            formik?.values?.liberadoVisualizacaoLocalidade?.selecionada || false
                                        }
                                        disabled={loading}
                                    />
                                    <label htmlFor="liberadoVisualizacaoLocalidade">{formik?.values?.liberadoVisualizacaoLocalidade?.valor}:</label>
                                    {authenticatedUser?.departamento}
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="liberadoVisualizacaoTelefone"
                                        onChange={
                                            type !== 'read'
                                            ?
                                            (e: React.ChangeEvent<HTMLInputElement>) =>
                                            formik?.setFieldValue('liberadoVisualizacaoTelefone.selecionada', e.target.checked)
                                            :
                                            () => false
                                        }
                                        checked={
                                            formik?.values?.liberadoVisualizacaoTelefone?.selecionada || false
                                        }
                                        disabled={loading}
                                    />
                                    <label htmlFor="liberadoVisualizacaoTelefone">{formik?.values?.liberadoVisualizacaoTelefone?.valor}:</label>
                                    {authenticatedUser?.celular}
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <h5>Liberação do curso</h5>
                        {loadingFetch ? (
                            <Spinner/>
                        ) : (
                            <div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="cursoLiberado"
                                        onChange={
                                            type !== 'read'
                                            ?
                                            (e: React.ChangeEvent<HTMLInputElement>) =>
                                            formik?.setFieldValue('cursoLiberado', e.target.checked)
                                            :
                                            () => false
                                        }
                                        checked={formik?.values?.cursoLiberado || false}
                                        disabled={loading}
                                    />
                                    <label htmlFor="cursoLiberado">Publicar curso</label>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                <Buttons>
                    <Button type="button" btnTheme="secondary" onClick={handleBackClick}>
                        Anterior
                    </Button>
                    <Button
                        type="button"
                        btnTheme="primary"
                        onClick={handleCoursePreviewClick}
                        disabled={checkDisabledSubmit()}
                    >
                        Pré-visualizar Curso
                    </Button>                        
                    <Button
                        type="submit"
                        btnTheme="primary"
                        disabled={checkDisabledSubmit()}
                    >
                        {loading ? <Spinner /> : formik.dirty ? 'Salvar Passo 5' : 'Próximo'}
                    </Button>                        
                </Buttons>
            </form>
        </Container>
    )
});

export default StepFive;