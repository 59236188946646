import { AuthUser, RegisterData } from '../../models/Auth';
import { AccessData } from '../../models/Auth';
import api from '../api';

const ENDPOINT = 'auth';

export const authService = {
    login,
    logout,
    list,
    registerNotAuthenticatedUser,
};

async function login(login: string, password: string): Promise<AccessData> {
    const { data } = await api.post<AccessData>(`${ENDPOINT}/login`, {
        username: login,
        password: password
    });

    return data;
};

async function list(): Promise<AuthUser> {
    const { data } = await api.get(`${ENDPOINT}/userInfo`);

    return data;
};

function logout() {
    localStorage.removeItem('user');
};

async function registerNotAuthenticatedUser(newUser: RegisterData): Promise<any> {
    const { data } = await api.put<RegisterData>(`${ENDPOINT}`, newUser);

    return data;
};