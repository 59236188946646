import styled from "styled-components";

export const Container = styled.div`
    h1 {
        margin: 40px 0 24px;
        
        @media (max-width: 768px) {
            justify-content: center;
        }
    }
`;