import React from 'react';
import Menu from '../Menu';
import Content from '../Content';
import { Container } from './styles';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

const MainBody: React.FC<Props> = ({ children }) => {

    return (
        <Container className="aux-main-body" /*className={open ? 'open' : 'closed'}*/>
            <Menu />
            {/* <MenuMobile/> */}
            <Content>
                { children }
            </Content>
        </Container>
    )
};

export default MainBody;