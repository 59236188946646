import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
    /* height: 100vh; */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    row-gap: 24px;
    justify-content: start;

    min-height: calc(100vh - 35px - 47px - 8px);

    @media (max-width: 576px) {
        /* min-height: calc(100vh - 35px - 47px - 8px - 72px + 58px); */
        min-height: calc(100vh - 32px - 72px);
        overflow-y: auto;
        height: calc(100vh - 32px - 72px);
    }   
`;

export const TopBrand = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    height: fit-content;
    flex-flow: wrap;

    > span {
        img {
            height: 100%;
        }

        &:first-child {
            display: flex;
            height: fit-content;
            height: 97px;
            min-height: 97px;

            @media (max-width: 1368px) {
                height: 58px;
                min-height: 58px;
            } 

            @media (max-width: 768px) {
                height: 37px;
                min-height: 37px;
            }
        }

        &:last-child {
            display: flex;
            height: fit-content;
            gap: 16px;

            > span {
                display: flex;
                height: 97px;
                min-height: 97px;

                @media (max-width: 1368px) {
                    height: 58px;
                    min-height: 58px;
                }

                @media (max-width: 768px) {
                    height: 37px;
                    min-height: 37px;
                }
            }
        }
    }
`;

export const FooterBrand = styled.div`
    display: flex;
    justify-content: start;
    align-self: start;
    width: 100%;
    padding: 0 16px;
    height: 58px;

    @media (max-width: 576px) {
        display: none
    }    

    img {
        height: 50px;
    }
`;

export const Infos = styled.div`
    justify-content: space-between;
    width: 450px;
    display: flex;
    color: ${proprs => proprs.theme.colors.linkText};

    @media (max-width: 576px) {
        display: none;
    }
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;

    > h2 {
        color: white;
        margin-left: 7px;
    }

    > img {
        height: 380px;
        width: 100%;
        pointer-events: none;

        @media (max-width: 1368px) { // em função de manter a tela sem scroll
            height: 210px;
        }

        @media (max-width: 576px) {
            height: 100%;
        }        
    }
`;

export const Form = styled.form`
    width: 400px;

    > div:first-child {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

    a {
        font-weight: bold;
    }

    @media (max-width: 576px) {
        width: auto;
        height: unset;
    }
`;

export const ForgottenPassword = styled.div`
    display: flex;
    justify-content: end;
    color: #5B0C0F;
`;

export const NotRegistered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;

    a {
        margin-left: 4px
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-self: center;
    justify-content: end;
    column-gap: 32px;

    button {
        width: 165px;
    }

    @media (max-width: 576px) {
        width: 100%;
        column-gap: 24px;
        justify-content: space-evenly;
    }
`;